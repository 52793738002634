// @flow

import React from 'react';
import type { Node } from 'react';
import { styled, Button, Divider, Grid } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useForm, FormProvider } from 'react-hook-form';
import RequestDetailsActions from 'components/RequestDetailsActions';
import { cdrStatuses, snackSeverityType } from 'constants/globalConsts';
import type { TypeFile } from 'routes/requestDetails/RequestDetails';
import type { SKU } from 'store/reducers/cdr';
import { saveButtonValue } from 'constants/requestDetailsConsts';
import { PdfGenerator } from 'xpand-components';
// $FlowFixMe
import { usePDF } from '@react-pdf/renderer';
import ConfigsTable from './calculationComponents/ConfigsTable';
import SkuTable from './calculationComponents/SkuTable';
import Options from './calculationComponents/Options';
import { getPdfStructureConfig } from './pdfConfig';

const styles = {
  cdrTable: {
    height: 'fit-content',
    paddingRight: '20px',
  },
  skuListTable: {
    height: 'fit-content',
    paddingLeft: '20px',
  },
  dividerRoot: {
    width: '100%',
    marginTop: (theme: Object) => theme.spacing(5),
  },
  quoteButton: {
    margin: '18px 8px 0 8px',
    width: '200px',
  },
  reportButtonIcon: {
    fontSize: '14px',
  },
  reportButtonText: {
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 400,
    margin: '0 0 0 8px',
    color: '#ffffff',
  },
  remediationButton: {
    margin: '18px 0 0 8px',
    width: '210px',
  },
  saveButton: {
    margin: '18px 8px 0 8px',
  },
};

type SkuItem = {
  qty: number,
  sku: SKU,
};

type CalculationProps = {|
  files: ?Array<TypeFile>,
  skuList: Array<SkuItem>,
  options: Object,
  requestStatus: string,
  handleFormSubmit: Function,
  disabled: boolean,
  showSnackControl: (message: string, severity: string) => void,
  handleDownloadCDR: Function,
  actionsDisabled: boolean,
  isXlsxLoading: {
    '1y': boolean,
    '3y': boolean,
  },
  handleDownloadXlsx: Function,
  handleDownloadReport: Function,
  contactInfo: {
    companyName: string,
    firstName: string,
    lastName: string,
  },
  cdrData: {
    id: string,
  },
|};

const ReportButtonText = styled('span')(() => styles.reportButtonText);

function CalculationForm(props: CalculationProps): Node {
  const {
    files,
    skuList,
    options,
    requestStatus,
    handleFormSubmit,
    showSnackControl,
    handleDownloadCDR,
    disabled,
    actionsDisabled,
    isXlsxLoading,
    handleDownloadXlsx,
    handleDownloadReport,
    contactInfo,
    cdrData,
  } = props;
  const methods = useForm();
  const { handleSubmit, getValues } = methods;
  const [dataChanged, setFormDataChanged] = React.useState(false);
  const [skuSnackShown, setSkuSnackShown] = React.useState(false);
  const [instance] = usePDF({
    document: (
      <PdfGenerator
        config={getPdfStructureConfig(files, contactInfo, cdrData)}
      />
    ),
  });
  const isReportLoading =
    instance.loading || Boolean(instance.error) || actionsDisabled;

  if (dataChanged && !skuSnackShown) {
    showSnackControl(
      'Please, save the Config Discovery Request if you want to see updated SKU data',
      snackSeverityType.info,
    );
    setSkuSnackShown(true);
  }

  if (!files) {
    return null;
  }

  const getActionLeftButtons = () =>
    requestStatus === cdrStatuses.completed && !disabled ? (
      <Button
        key="saveButton"
        sx={styles.saveButton}
        variant="contained"
        color="primary"
        type="submit"
        value={saveButtonValue}
        disabled={actionsDisabled}
        onClick={() => {
          setSkuSnackShown(false);
          setFormDataChanged(false);
        }}
      >
        Calculate
      </Button>
    ) : null;

  const getActionRightButtons = () =>
    options &&
    Object.keys(options).length > 0 && [
      <Button
        onClick={() => handleDownloadXlsx('1y')}
        disabled={isXlsxLoading['1y']}
        disableRipple={isXlsxLoading['1y']}
        key="quoteButton-1y"
        sx={styles.quoteButton}
        variant="contained"
        color="secondary"
      >
        <GetAppIcon
          sx={styles.reportButtonIcon}
          fontSize="small"
          color="primary"
        />

        <ReportButtonText>1 Year Quote .XLSX</ReportButtonText>
      </Button>,
      <Button
        onClick={() => handleDownloadXlsx('3y')}
        disabled={isXlsxLoading['3y']}
        disableRipple={isXlsxLoading['3y']}
        key="quoteButton-3y"
        sx={styles.quoteButton}
        variant="contained"
        color="secondary"
      >
        <GetAppIcon
          sx={styles.reportButtonIcon}
          fontSize="small"
          color="primary"
        />

        <ReportButtonText>3 Years Quote .XLSX</ReportButtonText>
      </Button>,
      <Button
        key="remediationButton"
        sx={styles.remediationButton}
        variant="contained"
        color="secondary"
        onClick={() =>
          handleDownloadReport(instance.url, 'Xpand_healthcheckreport')
        }
        disabled={isReportLoading}
        disableRipple={isReportLoading}
      >
        <GetAppIcon
          sx={styles.reportButtonIcon}
          fontSize="small"
          color="primary"
        />
        <ReportButtonText>Remediation Report</ReportButtonText>
      </Button>,
    ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container>
          <Grid container item lg={8} md={12} xl={8} sx={styles.cdrTable}>
            <ConfigsTable
              files={files}
              setFormDataChanged={setFormDataChanged}
              handleDownloadCDR={handleDownloadCDR}
              disabled={disabled}
            />

            <Options
              data={options}
              setFormDataChanged={setFormDataChanged}
              disabled={disabled}
              getValues={getValues}
            />
          </Grid>

          <Grid container item lg={4} md={12} xl={4} sx={styles.skuListTable}>
            <SkuTable data={skuList} />
          </Grid>

          <Divider sx={styles.dividerRoot} />

          <RequestDetailsActions
            leftButtons={getActionLeftButtons()}
            rightButtons={getActionRightButtons()}
          />
        </Grid>
      </form>
    </FormProvider>
  );
}

export default CalculationForm;
