// @flow

const setConfigDiscoveryRequestLoading = (loading: boolean): Object => ({
  type: '@configDiscoveryRequest/loading',
  payload: loading,
});

const createConfigDiscoveryRequest = (
  data: Object,
  onSuccess?: Function,
  onError?: Function,
): Object => ({
  type: '@configDiscoveryRequest/create',
  meta: {
    type: 'api',
    url: '/config-discovery-request',
    method: 'POST',
    body: data,
    onSuccess,
    onError,
    onLoading: setConfigDiscoveryRequestLoading,
  },
});

const editConfigDiscoveryRequestInfo = (
  id: string,
  data: Object,
  onSuccess?: Function,
  onError?: Function,
): Object => ({
  type: '@configDiscoveryRequest/edit-info',
  meta: {
    type: 'api',
    url: `/config-discovery-request/${id}/edit/info`,
    method: 'POST',
    body: data,
    onSuccess,
    onError,
    onLoading: setConfigDiscoveryRequestLoading,
  },
});

const editConfigDiscoveryRequestOptions = (
  id: string,
  data: Object,
  onSuccess?: Function,
  onError?: Function,
): Object => ({
  type: '@configDiscoveryRequest/edit-options',
  meta: {
    type: 'api',
    url: `/config-discovery-request/${id}/edit/options`,
    method: 'POST',
    body: data,
    onSuccess,
    onError,
    onLoading: setConfigDiscoveryRequestLoading,
  },
});

const getConfigDiscoveryRequest = (
  id: string,
  onSuccess?: Function,
): Object => ({
  type: '@configDiscoveryRequest/get-request-data',
  meta: {
    type: 'api',
    url: `/config-discovery-request/${id}`,
    method: 'GET',
    onSuccess,
    onLoading: setConfigDiscoveryRequestLoading,
  },
});

const resetConfigDiscoveryRequest = (): Object => ({
  type: '@configDiscoveryRequest/reset',
});

const cdrAction = (
  id: string,
  action: string,
  onSuccess?: Function,
  onError?: Function,
): Object => ({
  type: '@configDiscoveryRequest/action',
  meta: {
    type: 'api',
    url: `/config-discovery-request/${id}/${action}`,
    method: 'POST',
    onSuccess,
    onError,
    onLoading: setConfigDiscoveryRequestLoading,
  },
});

export {
  createConfigDiscoveryRequest,
  editConfigDiscoveryRequestInfo,
  editConfigDiscoveryRequestOptions,
  getConfigDiscoveryRequest,
  resetConfigDiscoveryRequest,
  setConfigDiscoveryRequestLoading,
  cdrAction,
};
