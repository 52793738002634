// @flow
import React from 'react';
import type { Node } from 'react';
// $FlowFixMe
import { styled } from '@mui/material';
import { Header, Footer } from 'xpand-components';
import { footerProps, headerProps } from 'constants/globalConsts';
import UserMenu from 'components/UserMenu';

type Props = {|
  children: Node,
|};

const styles = {
  mainContainer: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column wrap',
    flexGrow: 1,
    minHeight: '100vh',
    maxWidth: '100%',
  },
  bodyContainer: {
    flexGrow: 1,
    margin: '80px 120px 80px 120px',
  },
};

const MainContainer = styled('div')(() => styles.mainContainer);

const BodyContainer = styled('div')(() => styles.bodyContainer);

function Layout(props: Props): Node {
  const { children } = props;

  return (
    <MainContainer>
      <Header {...headerProps} userMenu={<UserMenu />} />

      <BodyContainer>{children}</BodyContainer>

      <Footer {...footerProps} />
    </MainContainer>
  );
}

export default Layout;
