// @flow

import React from 'react';
import type { Node } from 'react';
import { styled, Typography, Grid, Divider } from '@mui/material';
import type { SKU } from 'store/reducers/cdr';

const styles = {
  rootTable: {
    border: '1px solid #fafafa',
    padding: 0,
  },
  row: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
  },
  qty: {
    borderRight: '1px solid #CCCCCC',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  id: {
    textAlign: 'center',
    borderRight: 'none',
  },
  description: {
    paddingLeft: (theme: Object) => theme.spacing(1),
    borderRight: '1px solid #CCCCCC',
  },
  configsTableColLabel: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000000',
  },
  configsTableColHeaderLabel: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000000',
    paddingLeft: (theme: Object) => theme.spacing(1),
  },
  dividerRoot: {
    width: '100%',
    margin: 'auto',
  },
  tableWrapper: {
    border: '1px solid #CCCCCC',
    padding: '0 !important',
  },
};

type SkuItem = {
  qty: number,
  sku: SKU,
};

type Props = {|
  data: Array<SkuItem>,
|};

const StyledSKUGrid = styled(Grid)(({ theme }) => ({
  ...styles.row,
  ...styles.id,
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
  },
}));

function SkuTable(props: Props): Node {
  const { data } = props;

  return (
    <>
      <Grid alignItems="center" container item sx={styles.row}>
        <Grid item sm={1} md={1} lg={2} xl={2}>
          <Typography variant="inherit" sx={styles.configsTableColHeaderLabel}>
            Quantity
          </Typography>
        </Grid>

        <Grid item sm={9} md={9} lg={8} xl={8}>
          <Typography variant="inherit" sx={styles.configsTableColHeaderLabel}>
            Description
          </Typography>
        </Grid>

        <Grid item sm={2} md={2} lg={2} xl={2}>
          <Typography variant="inherit" sx={styles.configsTableColHeaderLabel}>
            SKU (1y.)
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        spacing={0}
        container
        alignItems="center"
        sx={styles.tableWrapper}
      >
        {data
          .filter((el) => !el.sku.isWebHidden)
          .map((sku, index) => {
            const gridKey = `${sku.qty || 0}_${index}`;

            return (
              <Grid
                item
                container
                alignItems="center"
                sx={styles.row}
                key={gridKey}
              >
                <Grid
                  sx={{ ...styles.row, ...styles.qty }}
                  item
                  sm={1}
                  md={1}
                  lg={2}
                  xl={2}
                >
                  <Typography
                    variant="inherit"
                    sx={styles.configsTableColLabel}
                  >
                    {sku.qty}
                  </Typography>
                </Grid>

                <Divider flexItem orientation="horizontal" />

                <Grid
                  sx={{ ...styles.row, ...styles.description }}
                  item
                  sm={9}
                  md={9}
                  lg={8}
                  xl={8}
                >
                  <Typography
                    variant="inherit"
                    sx={styles.configsTableColLabel}
                  >
                    {sku.sku.description}
                  </Typography>
                </Grid>

                <StyledSKUGrid item sm={2} md={2} lg={2} xl={2}>
                  <Typography
                    variant="inherit"
                    sx={styles.configsTableColLabel}
                  >
                    {sku.sku.id}
                  </Typography>
                </StyledSKUGrid>

                {index !== data.length - 1 && (
                  <Divider sx={styles.dividerRoot} />
                )}
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

export default SkuTable;
