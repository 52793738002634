const headerProps = {
  title: 'Xpand Config Discovery',
  breadcrumbsLinks: [
    { title: 'Requests', link: '/config-discovery-request', active: true },
  ],
};
const footerProps = {
  topFooter: {
    socialLinks: {
      facebook: 'https://www.facebook.com/arrowfiveyearsout',
      twitter: 'https://www.twitter.com/arrowglobal',
      linkedIn: 'https://www.linkedin.com/company/arrow-electronics',
    },
    contactText: 'CONTACT US',
    contactLink: 'https://www.arrow.com/ecs/xpand/contact-us/',
    logoLink: 'https://www.arrow.com/ecs/xpand/',
  },
  bottomFooter: {
    rightsText: '© 2021 by Arrow Electronics',
  },
};

const cdrStatuses = {
  draft: 'draft',
  pending: 'pending',
  completed: 'completed',
  expired: 'expired',
  all: 'all',
};

const snackSeverityType = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
};

const defaultErrorMessage = 'There was a problem';
const downloadFileErrorMessage = 'Error loading a config file';

const downloadCdrFileErrorMessages = {
  404: 'The file was not found',
  500: 'Something went wrong!',
};

const donwloadXlsxFileErrorMessages = {
  400: 'Invalid CDR id',
  404: 'The CDR was not found',
  422: 'The SKU list is missing!',
  500: 'Something went wrong!',
};

const snackMessageEmailSent =
  'You have successfully sent an email with the Config Discovery Request link.';
const snackMessageCopiedLink = 'The link was copied to the clipboard.';

export {
  headerProps,
  footerProps,
  cdrStatuses,
  snackSeverityType,
  defaultErrorMessage,
  downloadFileErrorMessage,
  downloadCdrFileErrorMessages,
  donwloadXlsxFileErrorMessages,
  snackMessageEmailSent,
  snackMessageCopiedLink,
};
