// @flow
import React from 'react';
import type { Node } from 'react';
import {
  styled,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
} from '@mui/material';
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown';

const styles = {
  loading: {
    width: 20,
    height: 20,
    position: 'absolute',
    right: (theme: Object) => theme.spacing(1),
  },
  disabled: {
    opacity: '0.3',
  },
  small: {
    width: '186px',
  },
  large: {
    width: '240px',
  },
  fullWidth: {
    width: '100%',
  },
  placeholder: {
    lineHeight: '24px',
    fontWeight: 400,
    color: '#939393',
    fontSize: '13px',
  },
  grey: {
    '& .MuiInputBase-formControl': {
      border: '1px solid #CCCCCC',
    },
  },
  select: {
    borderRadius: '5px',
  },
};

type Size = 'small' | 'large' | 'fullWidth';

type BorderColorType = ?'grey';

type SelectFormFieldConfig = {
  options: Array<{ value: string, label: string }>,
  required: boolean,
  InputLabelProps: Object,
  InputProps: Object,
  label: string,
  placeholder: string,
};

type FormFieldSelectProps = {|
  field: SelectFormFieldConfig,
  onChange: Function,
  value: string,
  error: any,
  renderLabel?: boolean,
  disabled?: boolean,
  loading?: boolean,
  size?: Size,
  testId?: string,
  borderColor: BorderColorType,
  styles: Object,
|};

const Placeholder = styled('div')(() => styles.placeholder);

function FormFieldSelect(props: FormFieldSelectProps): Node {
  const {
    field,
    onChange,
    value,
    error,
    renderLabel,
    disabled,
    testId,
    size,
    loading,
    borderColor,
    styles: propStyles,
  } = props;
  const { options, required, InputLabelProps, InputProps, label, placeholder } =
    field;

  const getSelectOptions = () =>
    options && [
      options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )),
    ];

  const formClasses = borderColor // $FlowFixMe
    ? { ...styles.grey, ...styles[size] }
    : // $FlowFixMe
      styles[size];

  return (
    <FormControl
      required={required}
      sx={{ ...formClasses, ...propStyles?.formControl }}
      data-testid={testId}
      variant="standard"
    >
      {renderLabel ? (
        <InputLabel {...InputLabelProps} sx={disabled ? styles.disabled : {}}>
          {label}
        </InputLabel>
      ) : null}

      <Select
        {...InputProps}
        IconComponent={() =>
          loading ? (
            <CircularProgress
              size="small"
              color="secondary"
              sx={styles.loading}
            />
          ) : (
            <IconArrowDropDown />
          )
        }
        disabled={disabled || loading}
        onChange={onChange}
        value={value}
        error={error && Boolean(error)}
        input={<Input />}
        displayEmpty
        sx={{
          ...styles.select,
          borderColor: error ? '#d32f2f' : '#757575',
        }}
        renderValue={
          value !== ''
            ? undefined
            : () => <Placeholder>{placeholder}</Placeholder>
        }
      >
        {getSelectOptions()}
      </Select>

      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
}

FormFieldSelect.defaultProps = {
  renderLabel: true,
  disabled: false,
  loading: false,
  size: 'small',
  testId: 'form-field-select',
};

export default FormFieldSelect;
