// @flow

import { cdrStatuses } from 'constants/globalConsts';

export type Status = 'all' | 'draft' | 'pending' | 'completed' | 'expired';

const { draft, pending, completed, expired, all } = cdrStatuses;
export const statuses = [all, draft, pending, completed, expired];

export type SKU = {
  id: string,
  description: string,
  isWebHidden: boolean,
};

export type ContactInfo = {
  companyName: string,
  addressLine1: string,
  addressLine2: string,
  country: string,
  city: string,
  zipCode: string,
  firstName: string,
  lastName: string,
  email: string,
};

export type Partner = {
  companyName: string,
  name: string,
  email: string,
  phone: string,
  country: string,
  communicateWithEmailOnly: false,
};

export type Creator = {
  email: string,
  family_name: string,
  name: string,
  sub: string,
  username: string,
};

export type ConfigDiscoveryRequest = {
  id: string,
  expires?: string,
  archived: false,
  createdAt: string,
  submittedAt?: string,
  creator: Creator,
  creatorUsername: string,
  status: Status,
  instructionsForCustomer: string,
  contactInfo: ContactInfo,
  partner: Partner,
  files: [
    {
      fileName: string,
      instanceName: string,
      adcFormFactor: string,
      adcLicense: string,
      analysis: {},
      level: string,
      status: string,
    },
  ],
  options: {
    admInstances: 1,
    sdxApplianceMgmt: 2,
    includeHealthCheck: false,
    excludeOnboardingFee: false,
  },
  skuList: ?Array<SKU>,
};

type TypeLastItem = {
  id: string,
  creatorUsername: string,
};

export type FilterType = {
  archived: boolean,
  creator: ?string,
  status: ?Status,
  label: ?string,
  lastItem: ?TypeLastItem,
  prevItem: ?Array<TypeLastItem>,
  currentItem: {
    id: string,
    creatorUsername: string,
    expires?: string,
    pageCount: number,
  },
};

export type GetListSuccess = {
  type: '@config/getList',
  payload: { items: Array<ConfigDiscoveryRequest>, lastItem: Object },
};

export type User = {
  username: string,
  attributes: {
    sub: string,
    email_verified: boolean,
    name: string,
    phone_number_verified: boolean,
    phone_number: string,
    family_name: string,
    email: string,
  },
};

type SetFilters = {
  type: '@config/setFilters',
  payload: Partial<FilterType>,
};
type UserType = {
  username: string,
  sub: string,
  name: string,
  family_name: string,
};
type AllUsers = {
  usersData: ?Array<UserType>,
  isUsersLoading: boolean,
};
export type StateType = {
  allUsers: AllUsers,
  currentUser: ?User,
  list: {
    items: Array<ConfigDiscoveryRequest>,
    isListLoading: boolean,
  },
  filters: FilterType,
};

type GetUsers = {
  type: '@config/getUsers',
  payload: ?Array<UserType>,
};

type SetCurrentUser = {
  type: '@config/setCurrentUser',
  payload: ?User,
};

type ResetPagination = {
  type: '@config/resetPagination',
};

type SetUsersLoading = {
  type: '@config/usersLoading',
  payload: boolean,
};

type SetConfigListLoading = {
  type: '@config/listLoading',
  payload: boolean,
};

export function initialExpiresDate(): string {
  const dateNow = new Date();
  const expires = new Date(new Date().setDate(dateNow.getDate() + 10));
  return expires.toISOString().toString();
}

export const initialStateCDR: StateType = {
  allUsers: {
    isUsersLoading: false,
    usersData: null,
  },
  currentUser: null,
  list: {
    items: [],
    isListLoading: false,
  },
  filters: {
    archived: false,
    creator: null,
    status: null,
    label: null,
    lastItem: null,
    prevItem: null,
    currentItem: {
      id: 'initial',
      expires: initialExpiresDate(),
      creatorUsername: '',
      pageCount: 1,
    },
  },
};

export type Action =
  | GetListSuccess
  | SetFilters
  | GetUsers
  | SetCurrentUser
  | ResetPagination
  | SetUsersLoading
  | SetConfigListLoading;
export default (
  // eslint-disable-next-line default-param-last
  state: StateType = initialStateCDR,
  action: Action,
): StateType => {
  switch (action.type) {
    case '@config/getList':
      return {
        ...state,
        list: { ...state.list, items: [...action.payload.items] },
        filters: {
          ...state.filters,
          lastItem: action.payload.lastItem,
        },
      };
    case '@config/listLoading':
      return {
        ...state,
        list: { ...state.list, isListLoading: action.payload },
      };
    case '@config/setFilters':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case '@config/usersLoading':
      return {
        ...state,
        allUsers: { ...state.allUsers, isUsersLoading: action.payload },
      };
    case '@config/getUsers':
      return {
        ...state,
        allUsers: { ...state.allUsers, usersData: action.payload },
      };
    case '@config/setCurrentUser':
      return {
        ...state,
        currentUser: action.payload,
      };
    case '@config/resetPagination':
      return {
        ...state,
        filters: {
          ...state.filters,
          lastItem: initialStateCDR.filters.lastItem,
          prevItem: initialStateCDR.filters.prevItem,
          currentItem: initialStateCDR.filters.currentItem,
        },
      };
    default:
      return state;
  }
};
