// @flow

function convertDate(date: string) {
  const updatedDate = date.split('-');
  updatedDate[2] = updatedDate[2].slice(0, 2);
  const returnDate = `${updatedDate[2]}/${updatedDate[1]}/${updatedDate[0]}`;
  return returnDate;
}

export default convertDate;
