// @flow
import React, { useRef, useState, useEffect } from 'react';
import type { Node } from 'react';
import {
  styled,
  Button,
  Avatar,
  MenuList,
  MenuItem,
  Grow,
  Popper,
  ClickAwayListener,
  Paper,
} from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';

const UserButton = styled(Button)(() => ({
  color: '#FFFFFF',
  fontSize: '11px',
  border: 'none',
  width: 'auto',
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  marginLeft: theme.spacing(2),
  color: '#000000',
  background: '#FFFFFF',
}));

const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

function UserMenu(): Node {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const { signOut, user } = useAuthenticator();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    // $FlowFixMe
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // $FlowFixMe
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const getUserAvatar = (): ?Node => {
    let result = '';
    if (user && user.attributes.name) {
      result = user.attributes.name.substring(0, 1).toUpperCase();
    }

    if (user && user.attributes.family_name) {
      result += user.attributes.family_name.substring(0, 1).toUpperCase();
    }

    if (result.length === 0) {
      return null;
    }

    return <UserAvatar>{result}</UserAvatar>;
  };

  return (
    user && (
      <Container>
        <UserButton
          // $FlowFixMe
          ref={anchorRef}
          onClick={handleToggle}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
        >
          {user.attributes.email} {getUserAvatar()}
        </UserButton>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper square>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    <MenuItem onClick={signOut}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Container>
    )
  );
}

export default UserMenu;
