// @flow
import React from 'react';
import type { Node } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

type CheckboxFormFieldConfig = {
  label: string,
  required: boolean,
};

type FormFieldCheckboxProps = {|
  field: CheckboxFormFieldConfig,
  onChange: Function,
  value: string,
  error: string,
  styles?: Object,
  disabled?: boolean,
  testId?: string,
|};

function FormFieldCheckbox(props: FormFieldCheckboxProps): Node {
  const { styles, field, onChange, value, error, disabled, testId } = props;
  const { label, required } = field;

  return (
    <FormControlLabel
      data-testid={testId}
      control={
        <Checkbox
          required={required}
          checked={Boolean(value)}
          onChange={onChange}
          error={error && Boolean(error)}
          color="secondary"
        />
      }
      label={
        <Typography variant="inherit" sx={styles && styles.checkboxLabel}>
          {label}
        </Typography>
      }
      disabled={disabled}
      sx={styles && styles.checkboxControl}
    />
  );
}

FormFieldCheckbox.defaultProps = {
  styles: {},
  disabled: false,
  testId: '',
};

export default FormFieldCheckbox;
