// @flow

// $FlowFixMe
import axios from 'axios';
import axiosWrapper from 'axiosWrapper';
import {
  donwloadXlsxFileErrorMessages,
  downloadCdrFileErrorMessages,
} from 'constants/globalConsts';

const openFile = (url, name) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  // $FlowFixMe
  document.body.appendChild(link);
  link.click();
};

const downloadFile = async (
  data: any,
  options: any,
  name: string,
  blobOptions?: Object,
) => {
  try {
    const blob = await axios.get(data, options);
    const url = window.URL.createObjectURL(new Blob([blob.data], blobOptions));
    openFile(url, name);
  } catch (err) {
    console.error(err);
  }
};

const downloadCdrFile = async (
  key: string,
  name: string,
  cdrId: string,
  onError: Function,
) => {
  try {
    const s3Url = `/s3-download-url/${cdrId}/uploads/${key}`;
    const url = await axiosWrapper(s3Url, {
      url: s3Url,
      method: 'GET',
      responseType: 'blob',
    });
    const options = {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Credentials': true,
      },
      credentials: 'include',
    };
    downloadFile(url.data, options, name);
  } catch (err) {
    console.error(err);
    onError(
      downloadCdrFileErrorMessages[err.response.status] ??
        'Something went wrong',
    );
  }
};

const downloadXlsFile = async (
  key: string,
  duration: string,
  onError: Function,
) => {
  try {
    const s3Url = `/generate-xls/${key}?duration=${duration}`;
    const name = 'config-discovery-request';
    const url = await axiosWrapper(s3Url, {
      url: `/generate-xls/${key}`,
      method: 'GET',
      responseType: 'blob',
    });
    const requestOptions = {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Credentials': true,
      },
      credentials: 'include',
    };
    const blobOptions = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };
    downloadFile(url.data, requestOptions, name, blobOptions);
  } catch (err) {
    console.error(err);
    onError(
      donwloadXlsxFileErrorMessages[err.response.status] ??
        'Something went wrong',
    );
  }
};

export { downloadCdrFile, downloadXlsFile, openFile };
