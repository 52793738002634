/* eslint-disable import/no-unresolved */
// @flow
/* istanbul ignore file */

import React from 'react';
import type { Node } from 'react';
import { Authenticator, Button } from '@aws-amplify/ui-react';
// $FlowFixMe
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';

function Authorization(): Node {
  const formFields = {
    signUp: {
      name: {
        order: 1,
        isRequired: true,
        label: 'First name *',
        placeholder: 'First name',
      },
      family_name: {
        order: 2,
        isRequired: true,
        label: 'Family name *',
        placeholder: 'Family name',
      },
      email: {
        order: 3,
        isRequired: true,
        label: 'Email *',
        placeholder: 'Email',
      },
      password: {
        order: 4,
        isRequired: true,
        label: 'Password *',
        placeholder: 'Password',
      },
      confirm_password: {
        order: 5,
        isRequired: true,
        label: 'Confirm Password *',
        placeholder: 'Confirm Password',
      },
      phone_number: {
        order: 6,
        label: 'Phone',
        placeholder: 'Phone',
      },
    },
  };
  const signUpAttributes = [
    'name',
    'family_name',
    'email',
    'password',
    'confirm_password',
    'phone_number',
  ];

  return (
    <Authenticator
      loginMechanisms={['email']}
      components={{
        SignIn: {
          Header() {
            return (
              <>
                <div style={{ padding: '32px 32px 0 32px' }}>
                  <Button
                    isFullWidth
                    onClick={() => Auth.federatedSignIn({ provider: 'Arrow' })}
                  >
                    Sign in with Arrow account
                  </Button>
                </div>

                <Authenticator.SignIn.Header />
              </>
            );
          },
        },
      }}
      formFields={formFields}
      signUpAttributes={signUpAttributes}
    />
  );
}

export default Authorization;
