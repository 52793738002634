// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
import {
  calculationOptionsConfig,
  configsTableScvLevelConfig,
} from 'constants/requestDetailsConsts';
import { styled, Typography, Box, Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { addRequestDataToObjectConfg } from 'utils/requestDetailsUtils';
import FormFieldText from 'components/FormFieldText';
import FormFieldCheckbox from 'components/FormFieldCheckbox';
import FormFieldSelect from 'components/FormFieldSelect';

const styles = {
  textFieldInput: {
    maxWidth: '70px',
  },
  textFormField: {
    minWidth: '70px',
  },
  optionsSections: {
    padding: '24px 32px',
    border: '1px solid #CCCCCC',
    borderRadius: '2px',
    width: '100%',
  },
  optionsTitle: {
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 400,
    color: '#000000',
  },
  optionsInputs: {
    fontSize: '13px',
    lineHeight: '48px',
    fontWeight: 400,
    color: '#000000',
    margin: '24px 0 32px 0',
    display: 'flex',
  },
  optionsInputTexts: {
    fontSize: '13px',
    lineHeight: '48px',
    fontWeight: 400,
  },
  checkboxLabel: {
    fontSize: '13px',
    lineHeight: '14px',
    fontWeight: 400,
    color: '#000000',
  },
  checkboxControl: {
    margin: '0 40px 0 0',
  },
  dropdown: {
    width: '180px',
  },
  optionsSingleInputGridItem: {
    gap: '0.5rem',
  },
  optionsSingleInputGridItemDownBrkpoint: {
    flexBasis: 'auto',
    maxWidth: 'none',
  },
  optionsInputsGridContainer: {
    margin: '24px 0 32px 0',
  },
};

type OptionsProps = {|
  data: Object,
  disabled: boolean,
  setFormDataChanged: (isDataChanged: boolean) => void,
  getValues: Function,
|};

const OptionsSections = styled('div')(({ theme }) => ({
  ...styles.optionsSections,
  [theme.breakpoints.up('lg')]: {
    margin: '26px 0 40px 0',
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: 26,
  },
}));

const OptionsTitle = styled('div')(() => styles.optionsTitle);

function Options(props: OptionsProps): Node {
  const { data, setFormDataChanged, disabled, getValues } = props;
  const valueMappedConfigData = addRequestDataToObjectConfg(
    data,
    calculationOptionsConfig,
  );
  const [optionsErrors, setOptionsErrors] = useState({
    [calculationOptionsConfig.admInstances.key]: null,
    [calculationOptionsConfig.sdxApplianceMgmt.key]: null,
  });

  const getCheckBoxField = (configField: Object) => {
    const { key } = configField;

    return (
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormFieldCheckbox
            disabled={disabled}
            field={configField}
            onChange={(e) => {
              setFormDataChanged(true);
              return onChange(e.target.checked);
            }}
            value={value}
            error={error}
            styles={styles}
            testId={`formFieldCheckbox-${key}`}
          />
        )}
        key={key}
        name={key}
        defaultValue={configField.value || false}
      />
    );
  };

  const getNumberInputField = (configField: Object) => {
    const { key } = configField;

    return (
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormFieldText
            disabled={disabled}
            field={configField}
            onChange={(e) => {
              const selectedValue = e.target.value || '0';

              setFormDataChanged(true);
              return onChange(selectedValue);
            }}
            value={value}
            error={error && Boolean(error)}
            type="number"
            styles={styles}
            data-testid={`formFieldText-${key}`}
          />
        )}
        key={key}
        name={key}
        defaultValue={configField.value?.value || '0'}
      />
    );
  };

  const getSelectInputField = (configField: Object) => {
    const { key } = configField;

    return (
      <Controller
        render={({ field: { onChange, value } }) => (
          <FormFieldSelect
            styles={{ formControl: styles.dropdown }}
            size="fullWidth"
            disabled={disabled}
            field={{ ...configsTableScvLevelConfig }}
            onChange={(e) => {
              setFormDataChanged(true);
              return onChange(e.target.value);
            }}
            value={value}
            error={optionsErrors[key]}
            renderLabel={false}
            testId={`formFieldSelect-${key}`}
          />
        )}
        rules={{
          validate: () => {
            const numberInputValue = getValues(key);
            const selectInputValue = getValues(`${key}/${key}-subscription`);
            const hasError = Number(numberInputValue) > 0 && !selectInputValue;

            setOptionsErrors((prev) => ({
              ...prev,
              [key]: hasError
                ? { message: 'Please fill out this field.' }
                : null,
            }));

            return !hasError;
          },
        }}
        key={`${key}/${key}-subscription`}
        name={`${key}/${key}-subscription`}
        defaultValue={configField.value?.level || ''}
      />
    );
  };

  return (
    <OptionsSections>
      <OptionsTitle>Options</OptionsTitle>

      <Grid container sx={styles.optionsInputsGridContainer}>
        <Grid
          container
          wrap="nowrap"
          item
          xs={6}
          sx={(theme) => ({
            ...styles.optionsSingleInputGridItem,
            [theme.breakpoints.down('xl')]:
              styles.optionsSingleInputGridItemDownBrkpoint,
          })}
        >
          <Typography variant="inherit" sx={styles.optionsInputTexts}>
            Include ADM instances
          </Typography>

          {getNumberInputField(valueMappedConfigData.admInstances)}

          {getSelectInputField(valueMappedConfigData.admInstances)}
        </Grid>

        <Grid
          item
          container
          wrap="nowrap"
          xs={6}
          sx={(theme) => ({
            ...styles.optionsSingleInputGridItem,
            [theme.breakpoints.down('xl')]: {
              ...styles.optionsSingleInputGridItemDownBrkpoint,
              marginTop: theme.spacing(2),
            },
          })}
        >
          <Typography variant="inherit" sx={styles.optionsInputTexts}>
            Include SDX appliance mgmt
          </Typography>

          {getNumberInputField(valueMappedConfigData.sdxApplianceMgmt)}

          {getSelectInputField(valueMappedConfigData.sdxApplianceMgmt)}
        </Grid>
      </Grid>

      <Box>
        {getCheckBoxField(valueMappedConfigData.includeHealthCheck)}

        {getCheckBoxField(valueMappedConfigData.excludeOnboardingFee)}
      </Box>
    </OptionsSections>
  );
}

export default Options;
