// @flow
import React from 'react';
import type { Node } from 'react';
import { styled, CircularProgress, Typography, Box } from '@mui/material';
import Filters from 'components/Filters';
import ConfigTitleRow from 'components/ConfigTitleRow';
import ConfigListRow from 'components/ConfigListRow';
// $FlowFixMe
import { useSelector } from 'react-redux';
import Separator from 'components/Separator';
import type { RootState } from 'reducers';

const styles = {
  uppermostRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  separator: {
    borderBottom: '1px solid #999999',
    width: '100%',
  },
  titleRowText: {
    fontSize: '1.5rem',
    lineHeight: '21px',
    fontWeight: 400,
    fontStyle: 'normal',
    margin: 'auto 0',
  },
  itemRow: {
    width: 240,
    height: 17,
  },
  itemRowContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: 61,
    borderBottom: '1px solid #999999',
    alignItems: 'center',
  },
  filtersWrapper: {
    margin: 'auto 0',
  },
  listContainer: {
    position: 'relative',
    marginBottom: (theme: Object) => theme.spacing(3),
    minHeight: 80,
    width: '100%',
  },
  tableLoaderWrapper: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableOpacity: {
    opacity: '0.5',
  },
};

const UppermostRow = styled('div')(({ theme }) => ({
  ...styles.uppermostRow,
  marginBottom: theme.spacing(2),
}));

const FiltersContainer = styled('div')(() => styles.filtersWrapper);

const TableLoaderContainer = styled('div')(() => styles.tableLoaderWrapper);

type Props = {|
  openArchiveDialog: Function,
  openExpiryDialog: Function,
  openRequestDetailsPage: Function,
  duplicateCDR: Function,
  archived: Function,
  handleSendEmail: Function,
  handleGetLink: Function,
|};

function CDRTable(props: Props): Node {
  const { items, isListLoading } = useSelector(
    /* istanbul ignore next */
    (state: RootState) => state.config.list,
  );
  const {
    openArchiveDialog,
    openExpiryDialog,
    openRequestDetailsPage,
    duplicateCDR,
    archived,
    handleSendEmail,
    handleGetLink,
  } = props;
  let tableContainerClasses = styles.listContainer;

  if (isListLoading) {
    tableContainerClasses = {
      ...tableContainerClasses,
      ...styles.tableOpacity,
    };
  }

  return (
    <Box>
      <UppermostRow>
        <Typography sx={styles.titleRowText} variant="h5">
          Config Discovery Requests
        </Typography>

        <FiltersContainer>
          <Filters />
        </FiltersContainer>
      </UppermostRow>

      <Separator sx={styles.separator} />

      <ConfigTitleRow />
      <Box data-testid="table-container" sx={tableContainerClasses}>
        {isListLoading && (
          <TableLoaderContainer>
            <CircularProgress color="secondary" size={80} />
          </TableLoaderContainer>
        )}

        {items &&
          items.map((el) => (
            <ConfigListRow
              key={el.id}
              handleArchive={() => openArchiveDialog(el)}
              handleExpiry={() => openExpiryDialog(el)}
              handleDetails={openRequestDetailsPage}
              handleSendEmail={handleSendEmail}
              handleGetLink={handleGetLink}
              duplicateCDR={duplicateCDR}
              archived={archived}
              data={el}
            />
          ))}
      </Box>
    </Box>
  );
}

export default CDRTable;
