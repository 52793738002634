// @flow
import React from 'react';
import headPageXpandPDF from 'assets/images/headPageXpandPDF.png';
import { HealthCheckFindings } from 'xpand-components';

const getPdfStructureConfig = (healthCheckFindings, contactInfo, cdrData) => {
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;

  return [
    // Page 1
    {
      page: {
        size: 'A4',
        className: 'pageHead',
      },
      content: [
        {
          src: headPageXpandPDF,
          className: 'image',
          type: 'image',
        },
        {
          value: `Customer: ${contactInfo && contactInfo.companyName}`,
          className: 'heading2',
          additionalStyles: { margin: '40px 0 10px 40px' },
          type: 'text',
        },
        {
          value: `Customer Contact: ${contactInfo && contactInfo.firstName} ${
            contactInfo && contactInfo.lastName
          }`,
          className: 'heading2',
          additionalStyles: { margin: '0 0 10px 40px' },
          type: 'text',
        },
        {
          value: `Report generated on: ${formattedDate}`,
          className: 'heading3',
          additionalStyles: { margin: '0 0 10px 40px' },
          type: 'text',
        },
        {
          value: `Ref: ${cdrData && cdrData.id}`,
          className: 'heading3',
          additionalStyles: { margin: '0 0 10px 40px' },
          type: 'text',
        },
      ],
    },

    // Page 2
    {
      page: {
        size: 'A4',
        className: 'page',
      },
      content: [
        // 1.1
        {
          value: '1. Introduction',
          className: 'heading1',
          type: 'text',
        },
        {
          value: '1.1. About this report',
          className: 'heading2',
          type: 'text',
        },
        {
          value:
            'This report was generated using Arrow XPand based on one or more Citrix ADC Configuration files. These configuration files are parsed and analysed in order to scan for any best practices. The report findings are based on:',
          className: 'defaultParagrpah',
          additionalStyles: { marginBottom: '10px' },
          type: 'text',
        },
        {
          value:
            '- Industry and vendor best practices such as the Citrix ADC Secure Deployment guide and other best practices and knowledge base articles.',
          className: 'listParagrpah1',
          type: 'text',
        },
        {
          value: '- Security Vulnerabilities disclosed by Citrix',
          className: 'listParagrpah1',
          type: 'text',
        },
        {
          value:
            '- Best practices based on the experience of Arrow’s Citrix ADC Managed Services team.',
          className: 'listParagrpah1',
          additionalStyles: { marginBottom: '10px' },
          type: 'text',
        },
        {
          value:
            'Note that the findings in this report are purely based on the Citrix ADC configuration file and other important parameters of a healthy ADC environment are not validated in this quick healthcheck. Arrow XPand also offers a full Citrix ADC Healthcheck where a manual in-depth audit is performed of the full Citrix ADC environment resulting in an extensive healthcheck report together with mitigation steps. ',
          className: 'defaultParagrpah',
          additionalStyles: { marginBottom: 0 },
          type: 'text',
        },
        {
          value: 'For more information see: ...',
          className: 'defaultParagrpah',
          additionalStyles: { marginBottom: '10px' },
          type: 'text',
        },

        // 1.2
        {
          value: '1.2. About Arrow XPand',
          className: 'heading2',
          type: 'text',
        },
        {
          value:
            'Arrow XPand is a portfolio of related tools and services offering a fully managed Citrix ADC Managed Service. The main components of the XPand portfolio are:',
          className: 'defaultParagrpah',
          additionalStyles: { marginBottom: '10px' },
          type: 'text',
        },
        {
          className: 'listParagrpah1',
          additionalStyles: { marginBottom: 0 },
          type: 'text',
          children: [
            {
              value: '- Config Analyzer: ',
              className: 'boldParagrpah',
              type: 'text',
            },
            {
              value:
                'This is a publicly available tool where Citrix ADC customers can get a free quick analysis of the health of their ADC environment. Registered users can use the Config Analyzer to save and share configuration snippets or easily filter and extract parts of the ADC configuration and their dependencies.',
              className: 'defaultParagrpah',
              type: 'text',
            },
          ],
        },
        {
          className: 'listParagrpah1',
          additionalStyles: { marginBottom: '10px' },
          type: 'text',
          children: [
            {
              value: 'To visit Config Analyzer browse to ',
              className: 'defaultParagrpah',
              type: 'text',
            },
            {
              value: 'https://app.xconfig.io',
              src: 'https://app.xconfig.io',
              className: 'linkParagrpah',
              type: 'link',
            },
          ],
        },
        {
          className: 'listParagrpah1',
          type: 'text',
          children: [
            {
              value: '- Citrix ADC Managed Service: ',
              className: 'boldParagrpah',
              type: 'text',
            },
            {
              value:
                'A service where daily ADC monitoring and management tasks are delegated to a highly specialized team of ADC experts featuring the following benefits:',
              className: 'defaultParagrpah',
              type: 'text',
            },
          ],
        },
        {
          value: 'o Easy onboarding by using a secure connector appliance',
          className: 'listParagrpah2',
          type: 'text',
        },
        {
          value:
            'o Proactive monitoring of key ADC metrics and logs, integrated backup service, SSL Certificate monitoring and many more.',
          className: 'listParagrpah2',
          type: 'text',
        },
        {
          value: 'o ADC Firmware lifecycle management and security patching',
          className: 'listParagrpah2',
          type: 'text',
        },
        {
          value:
            'o Continuous automatic best practice analysis and implementations',
          className: 'listParagrpah2',
          type: 'text',
        },
        {
          value: 'o Optional 24/7 Service Desk availability',
          className: 'listParagrpah2',
          additionalStyles: { marginBottom: '10px' },
          type: 'text',
        },

        // 1.3
        {
          value: '1.3. Contact Us',
          className: 'heading2',
          type: 'text',
        },
        {
          className: 'defaultParagrpah',
          type: 'text',
          children: [
            {
              value:
                'For more information about the components of the Arrow XPand portfolio please reach out to .... Or visit ',
              className: 'defaultParagrpah',
              type: 'text',
            },
            {
              value:
                'https://www.arrow.com/ecs/uk/services/arrow-managed-services/arrow-xpand/',
              src: 'https://www.arrow.com/ecs/uk/services/arrow-managed-services/arrow-xpand/',
              className: 'linkParagrpah',
              type: 'link',
            },
            {
              value: ' for more information.',
              className: 'defaultParagrpah',
              type: 'text',
            },
          ],
        },
      ],
    },

    // Page 3
    {
      page: {
        size: 'A4',
        className: 'page',
      },
      content: [
        {
          value: '2. HealthCheck Findings',
          className: 'heading1',
          additionalStyles: { marginBottom: 0 },
          type: 'text',
        },
        {
          type: 'component',
          component: (
            <HealthCheckFindings healthCheckFindings={healthCheckFindings} />
          ),
        },
        {
          value:
            'We recommend investigating any critical or major issues as soon as possible. It is also possible to get a full ADC HealthCheck and remediation plan as part of our ADC HealthCheck offering. Please contact us for more information.',
          className: 'defaultParagrpah',
          additionalStyles: { marginTop: '10px' },
          type: 'text',
        },
      ],
    },
  ];
};

// eslint-disable-next-line import/prefer-default-export
export { getPdfStructureConfig };
