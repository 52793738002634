import { validateEmail } from 'utils/validators';

const formFieldTypes = {
  inputFieldType: 'input',
  checkboxFieldType: 'checkbox',
  selectFieldType: 'select',
};

const emailErrorMessage = 'E-mail is required';

const countryOptions = [
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Kosovo', label: 'Kosovo' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Kingdom', label: 'United Kingdom' },
];

const customerDetailsFields = [
  [
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-companyName',
      label: 'Company Name',
      placeholder: 'Arrow Xpand',
      required: true,
      errorMessage: 'Company name is required',
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-addressLine1',
      label: 'Address line 1',
      placeholder: 'Address line 1',
      required: true,
      errorMessage: 'Address line 1 is required',
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-addressLine2',
      label: 'Address line 2',
      placeholder: 'Address line 2',
      required: false,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-city',
      label: 'City',
      placeholder: 'City',
      required: true,
      errorMessage: 'City is required',
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.selectFieldType,
      key: 'contactInfo-country',
      label: 'Country',
      placeholder: 'Choose',
      required: true,
      errorMessage: 'Country is required',
      options: countryOptions,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-zipCode',
      label: 'Zip-Code',
      placeholder: 'Zip-Code',
      required: true,
      errorMessage: 'Zip-Code is required',
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
  ],
  [
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-lastName',
      label: 'Your Last Name',
      placeholder: 'Last Name',
      required: true,
      errorMessage: 'Last Name is required',
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: 'input',
      key: 'contactInfo-firstName',
      label: 'Your First Name',
      placeholder: 'First Name',
      required: true,
      errorMessage: 'First Name is required',
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-email',
      label: 'E-mail',
      placeholder: 'E-mail',
      required: true,
      errorMessage: emailErrorMessage,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      validator: validateEmail,
      focused: true,
    },
  ],
];

const partnerDetailsFields = [
  [
    {
      type: formFieldTypes.inputFieldType,
      key: 'partner-companyName',
      label: 'Comapany name',
      placeholder: 'Arrow Xpand',
      required: true,
      errorMessage: 'Comapany name is required',
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.selectFieldType,
      key: 'partner-country',
      label: 'Country',
      placeholder: 'Choose',
      required: false,
      options: countryOptions,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'partner-email',
      label: 'Partner E-mail',
      placeholder: 'Partner Contact E-mail',
      required: false,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      validator: validateEmail,
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'partner-name',
      label: 'Partner Contact Name',
      placeholder: 'Partner Contact Name',
      required: false,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'partner-phone',
      label: 'Partner Contact Phone',
      placeholder: 'Partner Contact Phone',
      required: false,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.checkboxFieldType,
      key: 'partner-communicateWithEmailOnly',
      label: 'Only communicate using this Partner contact e-mail.',
      required: false,
      focused: true,
    },
  ],
];

const customizationFields = [
  [
    {
      type: formFieldTypes.inputFieldType,
      key: 'instructionsForCustomer',
      label: 'Instructions for customer',
      placeholder: 'Specific instruction for this request',
      required: false,
      fullWidth: true,
      multiline: true,
      rows: 10,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
  ],
];

const createFormState = 'create';
const editFormState = 'edit';
const saveButtonValue = 'save';
const sendButtonValue = 'send';

const configsTableScvLevelConfig = {
  key: 'options-level',
  placeholder: 'Choose',
  options: [
    { value: 'Platinum 24x7', label: 'Platinum 24x7' },
    { value: 'Gold', label: 'Gold' },
    { value: 'Base', label: 'Base' },
    { value: 'Platinum', label: 'Platinum' },
  ],
  InputLabelProps: { shrink: true, focused: false },
  InputProps: { disableUnderline: true },
};

const calculationOptionsConfig = {
  admInstances: {
    key: 'options-admInstances',
    InputLabelProps: { shrink: true, focused: false },
    InputProps: { disableUnderline: true, inputProps: { min: 0 } },
  },
  sdxApplianceMgmt: {
    key: 'options-sdxApplianceMgmt',
    InputLabelProps: { shrink: true, focused: false },
    InputProps: { disableUnderline: true, inputProps: { min: 0 } },
  },
  includeHealthCheck: {
    key: 'options-includeHealthCheck',
    label: 'Include HealthCheck',
  },
  excludeOnboardingFee: {
    key: 'options-excludeOnboardingFee',
    label: 'Exclude onboarding fee (Existing customer only)',
  },
};

export {
  formFieldTypes,
  customerDetailsFields,
  partnerDetailsFields,
  customizationFields,
  createFormState,
  editFormState,
  saveButtonValue,
  sendButtonValue,
  configsTableScvLevelConfig,
  calculationOptionsConfig,
  emailErrorMessage,
};
