// @flow
import React from 'react';
import type { Node } from 'react';
import { TextField } from '@mui/material';

type TextFormFieldConfig = {|
  InputLabelProps: Object,
  InputProps: Object,
  label: string,
  placeholder: string,
  required: boolean,
  type: string,
  multiline?: boolean,
  rows?: number,
  fullWidth?: boolean,
  key?: string,
  validator?: Function,
  errorMessage?: string,
|};

type FormFieldTextProps = {|
  field: TextFormFieldConfig,
  onChange: Function,
  value: string,
  error: any,
  type?: string,
  styles?: Object,
  endAdornment?: Node,
  disabled?: boolean,
  'data-testid'?: string,
|};

function FormFieldText(props: FormFieldTextProps): Node {
  const { styles, field, error, endAdornment, ...otherProps } = props;
  const { InputProps, multiline, validator, errorMessage, ...otherFieldProps } =
    field;

  return (
    <TextField
      {...otherFieldProps}
      {...otherProps}
      multiline={multiline}
      error={error && Boolean(error)}
      helperText={error ? error.message : null}
      InputProps={{
        ...InputProps,
        sx: styles && styles.textFieldInput,
        endAdornment,
      }}
      sx={
        multiline
          ? styles && styles.textFormFieldMultiLine
          : styles && styles.textFormField
      }
      variant="standard"
    />
  );
}

FormFieldText.defaultProps = {
  type: '',
  styles: {},
  endAdornment: null,
  disabled: false,
  'data-testid': '',
};

export default FormFieldText;
