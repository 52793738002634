// @flow

import * as React from 'react';
import type { Node } from 'react';
import {
  styled,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
// $FlowFixMe
import _ from 'lodash';
import IconVisibility from '@mui/icons-material/Visibility';
import IconMoreVert from '@mui/icons-material/MoreVert';
import type { ConfigDiscoveryRequest } from 'store/reducers/cdr';
import convertDate from 'utils/convertDate';
// $FlowFixMe
import { useSelector } from 'react-redux';
import type { RootState } from 'reducers';

const styles = {
  configListRowContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: 72,
    borderBottom: '1px solid #999999',
    alignItems: 'center',
  },
  itemRow: {
    width: 240,
    height: 17,
  },
  statusContainer: {
    width: 120,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: '0.8125rem',
    lineHeight: '1.43',
    fontWeight: 600,
    borderRadius: '5px',
  },
  divider: {
    border: '1px solid #CCCCCC',
    margin: `0 15px`,
  },
  statusDraft: {
    backgroundColor: '#CCCCCC',
  },
  statusPending: {
    backgroundColor: '#FFD674',
  },
  statusExpired: {
    backgroundColor: '#C92428',
  },
  statusCompleted: {
    backgroundColor: '#008445',
  },
  dateExpired: {
    color: '#C92428',
  },
  iconsRow: {
    display: 'flex',
    marginLeft: 'auto',
  },
  menuOption: {
    color: '#000000',
  },
  menuPaperProps: {
    style: {
      maxHeight: 165,
      width: 120,
      border: '1px solid #CCCCCC',
      borderRadius: '2px',
      color: '#CCCCCC',
      marginTop: 40,
    },
    elevation: 0,
  },
};

const menuExpiredOptions = ['Archive', 'Duplicate', 'Set expiry'];
const menuCompleted = ['Archive', 'Duplicate'];
const menuDuplicate = ['Duplicate'];
const menuActions = ['Duplicate', 'Send email', 'Get link'];

const MenuItemDivider = styled('div')(() => styles.divider);

type Props = {|
  data: ConfigDiscoveryRequest,
  archived: boolean,
  handleArchive: Function,
  handleExpiry: Function,
  handleDetails: Function,
  duplicateCDR: Function,
  handleSendEmail: Function,
  handleGetLink: Function,
|};

function ConfigListRow(props: Props): Node {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isListLoading } = useSelector(
    (state: RootState) => state.config.list,
  );
  const isDetailsLoading = useSelector(
    (state: RootState) => state.details.isLoading,
  );
  const open = Boolean(anchorEl);
  const {
    data,
    archived,
    duplicateCDR,
    handleArchive,
    handleExpiry,
    handleDetails,
    handleSendEmail,
    handleGetLink,
  } = props;
  const { contactInfo, createdAt, expires, creator, status, id, submittedAt } =
    data;
  const formattedExpires = expires && convertDate(expires);
  const formattedCreatedAt = convertDate(createdAt);
  const formatedSubmitted = submittedAt && convertDate(submittedAt);
  let expiresStyles = null;

  if (status === 'expired') {
    expiresStyles = styles.dateExpired;
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderStatus = (currentStatus: string): Node => {
    const upperFirstStatus = _.upperFirst(currentStatus);
    const statusClassName = `status${upperFirstStatus}`;

    return (
      <Box sx={{ ...styles.statusContainer, ...styles[statusClassName] }}>
        {upperFirstStatus}
      </Box>
    );
  };

  const handleOptionClick = (option: string, requestId: string) => {
    setAnchorEl(null);

    if (option === menuExpiredOptions[0]) {
      handleArchive();
    } else if (option === menuExpiredOptions[2]) {
      handleExpiry();
    } else if (option === menuExpiredOptions[1]) {
      duplicateCDR(data);
    } else if (option === menuActions[1]) {
      handleSendEmail(requestId);
    } else if (option === menuActions[2]) {
      handleGetLink(requestId);
    }

    return null;
  };

  return (
    <Grid
      container
      sx={styles.configListRowContainer}
      id="configListRowContainer"
      data-testid="config-list-row-container"
    >
      <Grid item alignItems="center" sm={6} md={6} lg={6} container>
        <Grid item sm={3} md={3} lg={3}>
          <Typography variant="body1">{contactInfo.companyName}</Typography>
        </Grid>

        <Grid item sm={3} md={3} lg={3} style={{ height: 'auto' }}>
          {renderStatus(status)}
        </Grid>

        <Grid item sm={6} md={6} lg={6}>
          <Typography variant="body1">{contactInfo.email}</Typography>
        </Grid>
      </Grid>

      <Grid item alignItems="center" sm={5} md={5} lg={5} container>
        <Grid item sm={3} md={3} lg={3}>
          <Typography variant="body1">{formattedCreatedAt}</Typography>
        </Grid>

        <Grid item sm={3} md={3} lg={3}>
          <Typography sx={expiresStyles} variant="body1">
            {formattedExpires}
          </Typography>
        </Grid>

        <Grid item sm={3} md={3} lg={3}>
          <Typography sx={expiresStyles} variant="body1">
            {formatedSubmitted}
          </Typography>
        </Grid>

        <Grid item sm={3} md={3} lg={3}>
          <Typography variant="body1">
            {creator.name} {creator.family_name}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container sm={1} lg={1} md={1} justifyContent="flex-end">
        <Grid item sm={6} md={6} lg={6} xl={4}>
          <IconButton
            disabled={isListLoading || isDetailsLoading}
            aria-label="more"
            data-testid="cdr-details-button"
            aria-controls="cdr-action-menu"
            aria-haspopup="true"
            onClick={() => handleDetails(id)}
            disableRipple={isListLoading || isDetailsLoading}
          >
            <IconVisibility size={24} fill="#CCCCCC" />
          </IconButton>
        </Grid>

        <Grid item sm={6} md={6} lg={6} xl={4}>
          <IconButton
            aria-label="more"
            data-testid="cdr-action-menu"
            aria-controls="action-menu"
            aria-haspopup="true"
            onClick={handleClick}
            disableRipple={isListLoading || isDetailsLoading}
          >
            <IconMoreVert size={24} fill="#CCCCCC" />
          </IconButton>

          <Menu
            id="action-menu"
            data-testid={`action-menu-${open ? 'open' : 'false'}`}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={styles.menuPaperProps}
          >
            {!archived &&
              status === 'expired' &&
              menuExpiredOptions.map((option, inx) => [
                <MenuItem
                  data-testid={`menu-${option.split(' ')[0]}`}
                  sx={styles.menuOption}
                  key={option}
                  onClick={() => handleOptionClick(option, id)}
                >
                  {option}
                </MenuItem>,
                inx !== menuExpiredOptions.length - 1 && (
                  <MenuItemDivider key={`${option}-divider`} />
                ),
              ])}
            {!archived &&
              status === 'completed' &&
              menuCompleted.map((option, inx) => [
                <MenuItem
                  data-testid={`menu-${option}`}
                  sx={styles.menuOption}
                  key={option}
                  onClick={() => handleOptionClick(option, id)}
                >
                  {option}
                </MenuItem>,
                inx !== menuCompleted.length - 1 && (
                  <MenuItemDivider key={`${option}-divider`} />
                ),
              ])}
            {!archived &&
              (status === 'pending' || status === 'draft') &&
              menuActions.map((option) => (
                <MenuItem
                  data-testid={`menu-${option}`}
                  sx={styles.menuOption}
                  key={option}
                  onClick={() => handleOptionClick(option, id)}
                >
                  {option}
                </MenuItem>
              ))}
            {archived &&
              menuDuplicate.map((option) => (
                <MenuItem
                  data-testid={`menu-${option}`}
                  sx={styles.menuOption}
                  key={option}
                  onClick={() => handleOptionClick(option, id)}
                >
                  {option}
                </MenuItem>
              ))}
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ConfigListRow;
