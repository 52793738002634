/* istanbul ignore file */

import { createTheme } from '@mui/material/styles';

const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#0069AA',
    },
    type: 'light',
    background: {
      default: '#fafafa',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 48,
          width: 120,
          textTransform: 'none',
          borderRadius: '2px',
          fontSize: 13,
          fontWeight: 400,
          lineHeight: '17.02px',
          textAlign: 'center',
        },
        outlinedPrimary: {
          color: '#505050',
          border: '1px solid #505050',
          borderRadius: '2px',
          '&:hover': {
            border: '1px solid #505050',
            borderRadius: '2px',
          },
        },
        containedPrimary: {
          backgroundColor: '#0069AA',
          color: '#ffffff',
          border: '1px solid #0069AA',
          borderRadius: '2px',
          '&:hover': {
            backgroundColor: '#0069AA',
          },
        },
        containedSecondary: {
          backgroundColor: '#505050',
          color: '#ffffff',
          border: '1px solid #505050',
          borderRadius: '2px',
          '&:hover': {
            backgroundColor: '#505050',
          },
        },
        text: {
          background: 'none',
          borderRadius: '2px',
          fontSize: 13,
          fontWeight: 400,
          lineHeight: '17.02px',
          textAlign: 'center',
          border: '1px solid #505050',
          height: 48,
          width: 120,
          color: '#505050',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 48,
          background: '#FFFFFF',
          border: '1px solid #757575',
          borderRadius: 2,
          '&$error': {
            border: '1px solid #C92428',
          },
        },
        input: {
          paddingLeft: '16px',
          color: '#000000',
          '&::placeholder': {
            lineHeight: '17px',
            fontWeight: 400,
            color: '#000000',
            fontSize: '13px',
          },
        },
        multiline: {
          height: 'auto',
        },
        inputMultiline: {
          margin: '10px 0 0 16px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '17px',
          lineHeight: '17px',
          fontWeight: 400,
          color: '#000000',
          '&$error': {
            color: '#C92428',
          },
        },
        asterisk: {
          '&$error': {
            color: '#C92428',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#000000',
          '&$error': {
            color: '#C92428',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0 8px 0 0',
        },
      },
    },
    MuiTypography: {
      button: {
        textTransform: 'none',
      },
      styleOverrides: {
        body1: {
          fontSize: '1rem',
          lineHeight: '1.5',
          fontStyle: 'normal',
          fontWeight: 400,
        },
        h6: {
          fontSize: '1.25rem',
          lineHeight: '1.6',
          fontWeight: 500,
          fontStyle: 'normal',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: 48,
          background: '#FFFFFF',
        },
      },
    },
  },
});

export default mainTheme;
