// @flow

import { setConfigListLoading } from './cdr';

export const archiveCDR = (
  id: string,
  onSuccess?: Function,
  onError?: Function,
) => ({
  type: '@dialog/setClose',
  meta: {
    url: `/config-discovery-request/${id}/archive`,
    type: 'api',
    method: 'POST',
    onSuccess,
    onError,
    onLoading: setConfigListLoading,
  },
});

export const expiryConfig = (
  id: string,
  onSuccess?: Function,
  onError?: Function,
) => ({
  type: '@dialog/setClose',
  meta: {
    url: `/config-discovery-request/${id}/expiry`,
    type: 'api',
    method: 'POST',
    onSuccess,
    onError,
    onLoading: setConfigListLoading,
  },
});

export const setDialogClose = () => ({
  type: '@dialog/setClose',
});

export const setDialogOpen = (payload) => ({
  type: '@dialog/setOpen',
  payload,
});
