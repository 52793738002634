// @flow

import type { ConfigDiscoveryRequest } from './cdr';

export type CreateCDR = {
  type: '@configDiscoveryRequest/create',
  payload: Object,
};

type EditCDR = {
  type: '@configDiscoveryRequest/edit-info',
  payload: Object,
};

type EditOptions = {
  type: '@configDiscoveryRequest/edit-options',
  payload: Object,
};

type GetCDR = {
  type: '@configDiscoveryRequest/get-request-data',
  payload: Object,
};

type ResetCDR = {
  type: '@configDiscoveryRequest/reset',
};

type IsCDRLoading = {
  type: '@configDiscoveryRequest/loading',
  payload: boolean,
};

type Action =
  | CreateCDR
  | EditCDR
  | EditOptions
  | GetCDR
  | ResetCDR
  | IsCDRLoading;

export type StateType = {
  current: ?ConfigDiscoveryRequest,
  isLoading: boolean,
};

export const initialState = {
  current: null,
  isLoading: false,
};

// eslint-disable-next-line default-param-last
export default (state: StateType = initialState, action: Action): StateType => {
  switch (action.type) {
    case '@configDiscoveryRequest/create':
    case '@configDiscoveryRequest/edit-info':
    case '@configDiscoveryRequest/edit-options':
    case '@configDiscoveryRequest/get-request-data':
      return {
        ...state,
        current: { ...action.payload },
      };
    case '@configDiscoveryRequest/reset':
      return initialState;
    case '@configDiscoveryRequest/loading':
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
