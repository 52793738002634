// @flow
import queryString from 'query-string';
import store from 'store';
import type { FilterType } from '../reducers/cdr';

export const updatedUrl = (data: Object, lastItem: Object): string => {
  const { archived, creator, status, label } = data;
  const { currentUser } = store.getState().config;
  const updCreator = creator ?? currentUser.Username;
  const url = queryString.stringify(
    { archived, creator: updCreator, status, label },
    { skipNull: true },
  );
  let lastItemQueries = '';
  if (lastItem) {
    let lastItemJsonString = JSON.stringify(lastItem);
    lastItemJsonString = lastItemJsonString.substring(
      1,
      lastItemJsonString.length - 1,
    );
    lastItemQueries = `&lastItem=${lastItemJsonString}`;
  }
  return `${url}${lastItemQueries}`;
};

export const setConfigListLoading = (isListLoading: boolean): Object => ({
  type: '@config/listLoading',
  payload: isListLoading,
});

export const getConfigList = (filter: any, lastItem: ?Object): Object => ({
  type: '@config/getList',
  meta: {
    type: 'api',
    url: `/config-discovery-request?${updatedUrl(filter, lastItem)}`,
    onLoading: setConfigListLoading,
  },
});

export const setCurrentUser = (payload: string): Object => ({
  type: '@config/setCurrentUser',
  payload,
});

export const setFilters = (payload: Partial<FilterType>): Object => ({
  type: '@config/setFilters',
  payload,
});

export const setUsersLoading = (isUsersLoading: boolean): Object => ({
  type: '@config/usersLoading',
  payload: isUsersLoading,
});

export const getUsers = (): Object => ({
  type: '@config/getUsers',
  meta: {
    type: 'api',
    url: '/users',
    onLoading: setUsersLoading,
  },
});

export const removeConfigFromList = (id: string): Object => {
  const { list } = store.getState().config;
  const newList = list.items.filter((el) => el.id !== id);
  return {
    type: '@config/getList',
    payload: { items: newList, lastItem: list.lastItem },
  };
};

export const updateConfig = (payload: Object): Object => {
  const { list } = store.getState().config;
  const updatedConfig = [...list.items];
  const itemIndex = list.items.findIndex((item) => item.id === payload.id);

  if (itemIndex > -1) {
    updatedConfig[itemIndex] = payload;
  } else {
    updatedConfig.push(payload);
  }

  return {
    type: '@config/getList',
    payload: { items: updatedConfig, lastItem: list.lastItem },
  };
};

export const resetPagination = (): Object => ({
  type: '@config/resetPagination',
});
