// @flow
import { Button, styled } from '@mui/material';
import React from 'react';
import type { Node } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import {
  addRequestDataToConfig,
  parseRequestCustomerDataToConfig,
} from 'utils/requestDetailsUtils';
import FormFieldSelect from 'components/FormFieldSelect';
import Separator from 'components/Separator';
import RequestDetailsActions from 'components/RequestDetailsActions';
import { cdrStatuses } from 'constants/globalConsts';
import type { ContactInfo, Partner, Creator } from 'store/reducers/cdr';
import {
  customerDetailsFields,
  customizationFields,
  emailErrorMessage,
  formFieldTypes,
  partnerDetailsFields,
  saveButtonValue,
} from 'constants/requestDetailsConsts';
import FormFieldText from 'components/FormFieldText';
import FormFieldCheckbox from 'components/FormFieldCheckbox';

const styles = {
  sectionText: {
    margin: '0 0 24px 0',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 400,
    color: '#000000',
  },
  fieldsSection: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 400,
    color: '#000000',
    margin: '8px 0 0 0',
  },
  checkboxLabel: {
    fontSize: '11px',
    lineHeight: '14px',
    fontWeight: 400,
    color: '#000000',
  },
  textFormField: {
    width: '100%',
    marginRight: (theme: Object) => theme.spacing(3),
    marginBottom: (theme: Object) => theme.spacing(1),
  },
  textFieldInput: {
    border: '1px solid #757575',
    borderRadius: '5px',
  },
  tableItem: {
    flex: '0 0 254px',
    display: 'flex',
    '&& >div': {
      width: '100%',
      marginRight: 24,
    },
  },
  tableRow: {
    display: 'flex',
    marginBottom: (theme: Object) => theme.spacing(4),
    flexWrap: 'wrap',
  },
  checkboxControl: {
    paddingTop: '12px',
    width: 'max-content',
    display: 'flex',
    height: '100%',
    marginLeft: 0,
  },
  customization: {
    width: '100%',
    marginBottom: (theme: Object) => theme.spacing(5),
    display: 'inherit',
  },
  saveButton: {
    margin: '18px 8px 0 8px',
  },
};

type RequestData = {
  archived?: boolean,
  contactInfo?: ContactInfo,
  createdAt?: string,
  creator?: Creator,
  creatorUsername?: string,
  id?: string,
  instructionsForCustomer?: string,
  partner?: Partner,
  status?: string,
};

type CustomerDetailsProps = {|
  requestData: RequestData,
  handleFormSubmit: Function,
  actionsDisabled: boolean,
|};

const TableItem = styled('div')(() => styles.tableItem);
const TableRow = styled('div')(() => styles.tableRow);
const SectionText = styled('div')(() => styles.sectionText);
const FieldsSection = styled('div')(() => styles.fieldsSection);

function CustomerDetailsForm(props: CustomerDetailsProps): Node {
  const { requestData, handleFormSubmit, actionsDisabled } = props;
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit, reset } = methods;
  const usePrevious = (value: any) => {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevPropValues = usePrevious({ requestData });
  const [requiredPartnerEmail, setRequiredPartnerEmail] = React.useState(
    requestData && requestData.partner
      ? requestData.partner.communicateWithEmailOnly
      : false,
  );

  React.useEffect(() => {
    if (prevPropValues && prevPropValues.requestData !== requestData) {
      /* istanbul ignore next */
      reset(parseRequestCustomerDataToConfig(requestData));
    }
  });

  const setConfigFieldData = (field: Object) => {
    let configField = { ...field };

    if (field.key === 'partner-email') {
      configField = {
        ...configField,
        required: requiredPartnerEmail,
        errorMessage: requiredPartnerEmail ? emailErrorMessage : '',
      };
    }

    return configField;
  };

  const handleFieldOnChange = (
    field: Object,
    onChange: Function,
    eventValue: any,
  ) => {
    if (field.key === 'partner-communicateWithEmailOnly') {
      setRequiredPartnerEmail(eventValue);
    }

    return onChange(eventValue);
  };

  const validateField = (field: Object, controllerProps: Object) => {
    const { type, validator } = field;
    const { value, error } = controllerProps;
    let validatedError = error;

    if (
      type === formFieldTypes.inputFieldType ||
      type === formFieldTypes.selectFieldType
    ) {
      validatedError = validator ? validator(value, error) : error;
    }

    return validatedError;
  };

  const getFieldControl = (configField: Object, controllerProps: Object) => {
    const { type } = configField;
    const { onChange, value } = controllerProps;
    const isFieldDisabled =
      requestData.status && requestData.status !== cdrStatuses.draft;
    let control = null;

    if (type === formFieldTypes.inputFieldType) {
      control = (
        <TableItem>
          <div>
            <FormFieldText
              field={configField}
              onChange={onChange}
              value={value}
              error={validateField(configField, controllerProps)}
              styles={styles}
              disabled={isFieldDisabled}
              data-testid={`formFieldText-${configField.key}`}
            />
          </div>
        </TableItem>
      );
    } else if (type === formFieldTypes.checkboxFieldType) {
      control = (
        <TableItem>
          <div>
            <FormFieldCheckbox
              field={configField}
              onChange={(e) =>
                handleFieldOnChange(configField, onChange, e.target.checked)
              }
              value={value}
              error={validateField(configField, controllerProps)}
              styles={styles}
              disabled={isFieldDisabled}
              testId={`formFieldCheckbox-${configField.key}`}
            />
          </div>
        </TableItem>
      );
    } else if (type === formFieldTypes.selectFieldType) {
      control = (
        <TableItem>
          <div>
            <FormFieldSelect
              field={configField}
              onChange={onChange}
              value={value}
              error={validateField(configField, controllerProps)}
              size="fullWidth"
              disabled={isFieldDisabled}
              variant="standard"
            />
          </div>
        </TableItem>
      );
    }

    return control;
  };

  const buildFieldControls = (
    fieldsRows: Array<any>,
    customization?: boolean,
  ) => {
    const fieldSections = [];

    fieldsRows.forEach((fields, index) => {
      const fieldControls = fields.map((configField) => {
        const field = setConfigFieldData(configField);
        const { key, errorMessage, type } = field;
        let controllerProps = {
          key,
          name: key,
          rules: { required: errorMessage },
        };

        if (type === formFieldTypes.inputFieldType) {
          controllerProps = {
            ...controllerProps,
            defaultValue: field.value || '',
          };
        } else if (type === formFieldTypes.checkboxFieldType) {
          controllerProps = {
            ...controllerProps,
            defaultValue: field.value || false,
          };
        } else if (type === formFieldTypes.selectFieldType) {
          controllerProps = {
            ...controllerProps,
            defaultValue: field.value || '',
          };
        }

        return (
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) =>
              getFieldControl(field, { onChange, value, error })
            }
            {...controllerProps}
          />
        );
      });

      fieldSections.push(
        <TableRow
          key={`fieldsSection-${fieldsRows.length + index}`}
          sx={customization ? styles.customization : styles.tableRow}
        >
          {fieldControls}
        </TableRow>,
      );
    });

    return fieldSections;
  };

  const getActionButtons = () =>
    !requestData.status || requestData.status === cdrStatuses.draft
      ? [
          <Button
            key="saveButton"
            sx={styles.saveButton}
            variant="contained"
            color="primary"
            type="submit"
            disabled={actionsDisabled}
            onClick={handleSubmit((data) =>
              handleFormSubmit(data, saveButtonValue),
            )}
          >
            Save
          </Button>,
        ]
      : null;

  return (
    <FormProvider {...methods}>
      <form>
        <div key="customerDetailsFields">
          <SectionText>Customer details</SectionText>

          <div>
            {buildFieldControls(
              addRequestDataToConfig(
                requestData.contactInfo,
                customerDetailsFields,
              ),
            )}
          </div>
        </div>

        <FieldsSection key="partnerDetailsFields">
          <SectionText>Partner details</SectionText>

          <div>
            {buildFieldControls(
              addRequestDataToConfig(requestData.partner, partnerDetailsFields),
            )}
          </div>
        </FieldsSection>

        <FieldsSection key="customizationFields">
          <SectionText>Customization</SectionText>

          <div>
            {buildFieldControls(
              addRequestDataToConfig(requestData, customizationFields),
              true,
            )}
          </div>
        </FieldsSection>

        <Separator />

        <RequestDetailsActions leftButtons={getActionButtons()} />
      </form>
    </FormProvider>
  );
}

export default CustomerDetailsForm;
