// @flow

import React from 'react';
import type { Node } from 'react';
import { Grid, Typography } from '@mui/material';

const styles = {
  itemRowContainer: {
    width: '100%',
    height: 61,
    borderBottom: '1px solid #999999',
    alignItems: 'center',
  },
};

function ConfigTitleRow(): Node {
  return (
    <Grid container sx={styles.itemRowContainer}>
      <Grid item sm={6} md={6} lg={6} container>
        <Grid item sm={3} md={3} lg={3}>
          <Typography variant="h6">Customer</Typography>
        </Grid>

        <Grid item sm={3} md={3} lg={3}>
          <Typography variant="h6">Status</Typography>
        </Grid>

        <Grid item sm={6} md={6} lg={6}>
          <Typography variant="h6">Customer contact</Typography>
        </Grid>
      </Grid>

      <Grid item sm={5} md={5} lg={5} container>
        <Grid item sm={3} md={3} lg={3}>
          <Typography variant="h6">Initiated</Typography>
        </Grid>

        <Grid item sm={3} md={3} lg={3}>
          <Typography variant="h6">Expires</Typography>
        </Grid>

        <Grid item sm={3} md={3} lg={3}>
          <Typography variant="h6">Submitted</Typography>
        </Grid>

        <Grid item sm={3} md={3} lg={3}>
          <Typography variant="h6">Creator</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ConfigTitleRow;
