// @flow
import type { Payload as PayloadSnack } from '../reducers/snack';

const showSnack = (
  payload: PayloadSnack,
): { type: string, payload: PayloadSnack } => ({
  type: '@app/snack-show',
  payload,
});

const hideSnack = (): { type: string } => ({
  type: '@app/snack-hide',
});

export { showSnack, hideSnack };
