// @flow

import React from 'react';
import type { Node } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
// $FlowFixMe
import { useDispatch, useSelector } from 'react-redux';
import { setDialogClose } from 'store/actions/dialog';
import type { RootState } from 'reducers';

const styles = {
  root: {
    '& .MuiDialogTitle-root': {
      color: 'rgba(255, 255, 255)',
      flex: '0 0 auto',
      margin: 0,
      padding: '16px 24px',
    },
    '& .MuiPaper-root': {
      backgroundColor: '#424242',
    },
    '& .MuiButton-root': {
      color: '#90caf9',
      minWidth: 64,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '& .MuiDialogContentText-root': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
  },
};
function DialogComponent(): Node {
  const { isOpen, title, textBody, callback } = useSelector(
    /* istanbul ignore next */
    (state: RootState) => state.dialog,
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setDialogClose());
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={styles.root}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {textBody}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          data-testid="dialog-disagree-button"
        >
          Disagree
        </Button>
        <Button
          data-testid="dialog-agree-button"
          onClick={callback}
          color="primary"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogComponent;
