// @flow

export type StateType = {
  isOpen: boolean,
  title: ?string,
  textBody: ?string,
  callback: Function,
};

export type SetOpen = {
  type: '@dialog/setOpen',
  payload: StateType,
};

export type SetClose = {
  type: '@dialog/setClose',
};

export const initialStateDialog: StateType = {
  isOpen: false,
  title: '',
  textBody: '',
  callback: () => {},
};

export type Action = SetOpen | SetClose;

export default (
  // eslint-disable-next-line default-param-last
  state: StateType = initialStateDialog,
  action: Action,
): StateType => {
  switch (action.type) {
    case '@dialog/setOpen':
      return action.payload;
    case '@dialog/setClose':
      return initialStateDialog;
    default:
      return state;
  }
};
