// @flow

/* istanbul ignore file */
// $FlowFixMe
import axios from 'axios';
import { Auth } from 'aws-amplify';

export type Errors = [{ [string]: string }];

const { REACT_APP_API_URL } = process.env;

if (!window.Promise) {
  window.Promise = Promise;
}

async function axiosWrapper(url: string, options: any): Promise<any> {
  if (!REACT_APP_API_URL) {
    throw new Error('No Request URL was provided!');
  }

  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
      Authorization: token,
    },
  };

  if ('headers' in options) {
    config.headers = {
      ...options.headers,
    };
  }

  return axios({
    method: options.method || 'GET',
    url,
    data: options.body || null,
    headers: config.headers,
    baseURL: REACT_APP_API_URL,
    credentials: 'include',
    cancelToken: options.cancelToken,
  });
}

export default axiosWrapper;
