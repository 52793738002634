// @flow

import React from 'react';
import type { Node } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Authorization from 'routes/Authorization';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'store/actions/cdr';

const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AWS_USER_POOL_DOMAIN,
  REACT_APP_AWS_USER_POOL_REDIRECTURI,
} = process.env;
const AuthConfiguration = {
  region: REACT_APP_AWS_REGION,
  userPoolId: REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    clientID: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    domain: REACT_APP_AWS_USER_POOL_DOMAIN,
    responseType: 'code',
    redirectUri: REACT_APP_AWS_USER_POOL_REDIRECTURI,
    scope: ['profile', 'openid', 'email', 'aws.cognito.signin.user.admin'],
    redirectSignIn: REACT_APP_AWS_USER_POOL_REDIRECTURI,
    redirectSignOut: REACT_APP_AWS_USER_POOL_REDIRECTURI,
  },
};

Amplify.configure({
  Auth: AuthConfiguration,
});
Auth.configure(AuthConfiguration);

type Props = { children: Node };

function RequireAuth({ children }: Props): Node {
  const { route, user } = useAuthenticator();
  const dispatch = useDispatch();

  if (route === 'authenticated') {
    dispatch(setCurrentUser(user));

    return children;
  }

  return <Authorization />;
}

export default RequireAuth;
