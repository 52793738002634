// @flow
/* istanbul ignore file */

import React, { useState, useEffect } from 'react';
import type { Node } from 'react';
// $FlowFixMe
import { useSelector, useDispatch } from 'react-redux';
import {
  createConfigDiscoveryRequest,
  editConfigDiscoveryRequestInfo,
  editConfigDiscoveryRequestOptions,
  getConfigDiscoveryRequest,
  resetConfigDiscoveryRequest,
} from 'store/actions/requestDetails';
import Layout from 'components/Layout';
import { createFormState, editFormState } from 'constants/requestDetailsConsts';
import RequestDetailsTitle from 'components/RequestDetailsTitle';
import Separator from 'components/Separator';
import { Box, CircularProgress } from '@mui/material';
import CustomerDetailsForm from 'components/CustomerDetailsForm';
import CalculationForm from 'components/CalculationForm';
import {
  buildRequestInfoData,
  buildRequestOptionsData,
} from 'utils/requestDetailsUtils';
import {
  cdrStatuses,
  defaultErrorMessage,
  snackSeverityType,
} from 'constants/globalConsts';
import { showSnack } from 'store/actions/snack';
import { downloadCdrFile, downloadXlsFile, openFile } from 'utils/downloadFile';
import type { RootState } from 'reducers';
import { useNavigate, useParams } from 'react-router-dom';

const styles = {
  formTabs: {
    margin: '32px 0 8px 0',
  },
  circularProgressWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export type TypeFile = {
  adcFormFactor: string,
  adcLicense: string,
  analysis: Object,
  bucketFileName: string,
  fileName: string,
  id: string,
  instanceName: string,
  status: string,
  url: string,
  level: string,
  isLoading: boolean,
};

function RequestDetails(): Node {
  const navigate = useNavigate();
  const { id: requestId } = useParams();
  const [formState, setFormState] = useState(createFormState);
  const [requestData, setRequestData] = useState({
    id: null,
    options: null,
    skuList: null,
    status: null,
    archived: null,
    contactInfo: null,
  });
  const [currentTab, setCurrentTab] = useState(0);
  const [files, setFiles] = useState<?Array<Object>>();
  const [isXlsxLoading, setIsXlsxLoading] = useState({
    '1y': false,
    '3y': false,
  });
  const { current: currentCDR, isLoading } = useSelector(
    (state: RootState) => state.details,
  );
  const { currentUser: userData } = useSelector(
    (state: RootState) => state.config,
  );
  const dispatch = useDispatch();

  const buildRequestDetailsData = () => {
    const formStateType = requestId ? editFormState : createFormState;

    if (formStateType === editFormState && currentCDR) {
      if (currentCDR.files) {
        const updatedFiles = currentCDR.files.map((file) => ({
          ...file,
          isLoading: false,
        }));

        setFiles(updatedFiles);
      } else {
        setFiles(null);
      }

      setCurrentTab(currentCDR.status === cdrStatuses.completed ? 1 : 0);
      // $FlowFixMe
      setRequestData(currentCDR);
    } else if (formStateType === createFormState) {
      setCurrentTab(0);
      setFormState(formStateType);
    }

    setFormState(formStateType);
  };

  useEffect(() => {
    if (requestId) {
      dispatch(getConfigDiscoveryRequest(requestId));
    }

    buildRequestDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    buildRequestDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCDR]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(resetConfigDiscoveryRequest()), []);

  const handleCustomerDetailsFormSubmit = (
    formData: Object,
    actionType: string,
  ) => {
    if (formState === createFormState && userData) {
      const { username, attributes } = userData;
      const { email, sub, family_name, name } = attributes;
      const requestCreateData = buildRequestInfoData(formData, actionType, {
        username,
        name,
        sub,
        email,
        family_name,
      });

      dispatch(
        createConfigDiscoveryRequest(
          requestCreateData,
          (data) => {
            if (data) {
              dispatch(
                showSnack({
                  message:
                    'You have successfully created a Config Discovery Request',
                  severity: snackSeverityType.success,
                }),
              );
              navigate('/config-discovery-request');
            }
          },
          (error) => {
            const { response } = error;
            const { data, status } = response;

            if (status === 424) {
              navigate(`/request-details/${data.item.id}`);
            }
          },
        ),
      );
    } else if (formState === editFormState && requestData) {
      const requestInfoData = buildRequestInfoData(formData, actionType);

      dispatch(
        editConfigDiscoveryRequestInfo(
          // $FlowFixMe
          requestData.id,
          requestInfoData,
          (data) => {
            if (data) {
              const snackMessage =
                data.status === cdrStatuses.draft
                  ? 'You have successfully saved the Config Discovery Request'
                  : 'You have successfully saved and sent the Config Discovery Request';

              dispatch(
                showSnack({
                  message: snackMessage,
                  severity: snackSeverityType.success,
                }),
              );
              navigate('/config-discovery-request');
            }
          },
          (error) => {
            const { response } = error;
            const { data, status } = response;

            if (status === 424) {
              dispatch(
                showSnack({
                  message: data ? data.errors[0] : defaultErrorMessage,
                  severity: snackSeverityType.error,
                }),
              );
            } else if (status === 500) {
              navigate('/config-discovery-request');
            }
          },
        ),
      );
    }
  };

  const handleDownloadXlsx = async (duration: '1y' | '3y') => {
    if (isXlsxLoading[duration]) {
      return null;
    }

    const onError = (message: string) => {
      dispatch(
        showSnack({
          message,
          severity: snackSeverityType.error,
        }),
      );
      setIsXlsxLoading((prevState) => ({
        ...prevState,
        // $FlowFixMe
        [duration]: false,
      }));
    };

    setIsXlsxLoading((prevState) => ({
      ...prevState,
      // $FlowFixMe
      [duration]: true,
    }));
    if (currentCDR) {
      await downloadXlsFile(currentCDR.id, duration, onError);
    }
    return setIsXlsxLoading((prevState) => ({
      ...prevState,
      // $FlowFixMe
      [duration]: false,
    }));
  };

  const handleDownloadCDR = async (file: TypeFile) => {
    if (!files || file.isLoading || !file.bucketFileName) {
      return null;
    }

    const handleFilesUpdate = (
      id: string,
      isFilesUpdateLoading: boolean,
    ): Array<TypeFile> =>
      files.map((el) => (el.id === id ? { ...el, isFilesUpdateLoading } : el));

    const onError = (message: string) => {
      dispatch(
        showSnack({
          message,
          severity: snackSeverityType.error,
        }),
      );
      setFiles(handleFilesUpdate(file.id, false));
    };

    setFiles(handleFilesUpdate(file.id, true));
    if (currentCDR) {
      await downloadCdrFile(
        file.bucketFileName,
        file.instanceName,
        currentCDR.id,
        onError,
      );
    }
    return setFiles(handleFilesUpdate(file.id, false));
  };

  const handleDownloadReport = (url: string, name: string) =>
    openFile(url, name);

  const handleCalculationFormSubmit = (formData: Object) => {
    const requestOptionsData = buildRequestOptionsData(formData);

    if (requestData && requestData.id) {
      dispatch(
        editConfigDiscoveryRequestOptions(
          requestData.id,
          requestOptionsData,
          () => {
            dispatch(
              showSnack({
                message:
                  'You have successfully saved the Config Discovery Request options',
                severity: snackSeverityType.success,
              }),
            );
          },
        ),
      );
    }
  };

  return (
    <Layout>
      {currentTab === null ? (
        <Box sx={styles.circularProgressWrapper}>
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <div>
          <RequestDetailsTitle
            formState={formState}
            requestStatus={requestData.status}
            title={
              (requestData &&
                requestData.contactInfo &&
                requestData.contactInfo.companyName) ||
              ''
            }
            currentTab={currentTab}
            handleTabChange={(event, tab) => setCurrentTab(tab)}
          />

          <Separator />

          <Box sx={styles.formTabs}>
            {currentTab === 0 && (
              <CustomerDetailsForm
                requestData={requestData}
                handleFormSubmit={handleCustomerDetailsFormSubmit}
                actionsDisabled={isLoading}
              />
            )}
            {currentTab === 1 && (
              <CalculationForm
                files={files}
                options={requestData.options || {}}
                skuList={requestData.skuList || []}
                requestStatus={requestData.status || ''}
                disabled={requestData.archived || false}
                contactInfo={requestData.contactInfo}
                cdrData={{ id: requestData.id }}
                handleFormSubmit={handleCalculationFormSubmit}
                handleDownloadCDR={handleDownloadCDR}
                showSnackControl={(message, severity) =>
                  dispatch(showSnack({ message, severity }))
                }
                actionsDisabled={isLoading}
                isXlsxLoading={isXlsxLoading}
                handleDownloadXlsx={handleDownloadXlsx}
                handleDownloadReport={handleDownloadReport}
              />
            )}
          </Box>
        </div>
      )}
    </Layout>
  );
}

export default RequestDetails;
