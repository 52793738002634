// @flow

import React from 'react';
import type { Node } from 'react';
import {
  styled,
  Typography,
  CircularProgress,
  Link,
  Grid,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import type { TypeFile } from 'routes/requestDetails/RequestDetails';
import { Analysis } from 'xpand-components';
import { configsTableScvLevelConfig } from 'constants/requestDetailsConsts';
import FormFieldSelect from 'components/FormFieldSelect';

const styles = {
  configsTableColLabel: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000000',
  },
  configsTableColHeaderLabel: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000000',
    padding: '8px 0 0 8px',
  },
  row: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
  },
  statusBanner: {
    maxWidth: 120,
    backgroundColor: '#008445',
    border: '1px solid #008445',
    borderRadius: '2px',
    textAlign: 'center',
  },
  statusBannerText: {
    fontSize: '11px',
    lineHeight: '31px',
    fontWeight: 400,
    color: '#ffffff',
    textTransform: 'uppercase',
  },
  linkRoot: {
    color: '#0069AA',
    width: 'inherit',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'start',
    cursor: 'pointer',
  },
  circularProgressWrapper: {
    textAlign: 'center',
    margin: 'auto',
  },
  rowItem: {
    paddingLeft: (theme: Object) => `${theme.spacing(1)}px`,
  },
  tableWrapper: {
    borderTop: '1px solid #999999',
    borderTopWidth: 'calc(100% - 16px)',
    '&& >.MuiGrid-item': {
      margin: 0,
    },
  },
  tableRow: {
    borderBottom: '1px solid #CCCCCC',
  },
};

type ConfigsTableProps = {|
  files: ?Array<TypeFile>,
  setFormDataChanged: (isDataChanged: boolean) => void,
  handleDownloadCDR: Function,
  disabled: boolean,
|};

const StatusBanner = styled('div')(() => styles.statusBanner);

const StatusBannerText = styled('span')(() => styles.statusBannerText);

const CircularProgressWrapper = styled('div')(
  () => styles.circularProgressWrapper,
);

const AnalysisGrid = styled(Grid)(({ theme }) => ({
  ...styles.rowItem,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));

const AnalysisTitleGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    display: 'flex',
  },
}));

function ConfigsTable(props: ConfigsTableProps): Node {
  const { files, setFormDataChanged, handleDownloadCDR, disabled } = props;

  if (!files) {
    return null;
  }

  return (
    <>
      <Grid alignItems="center" container item sx={styles.row}>
        <Grid item md={1} sm={1} lg={1} xl={2}>
          <Typography variant="inherit" sx={styles.configsTableColHeaderLabel}>
            Status
          </Typography>
        </Grid>

        <Grid item md={2} lg={2} xl={2}>
          <Typography variant="inherit" sx={styles.configsTableColHeaderLabel}>
            Instance (pair) Name
          </Typography>
        </Grid>

        <Grid item md={2} lg={2} xl={2}>
          <Typography variant="inherit" sx={styles.configsTableColHeaderLabel}>
            ADC Form Factor
          </Typography>
        </Grid>

        <Grid item md={2} lg={2} xl={2}>
          <Typography variant="inherit" sx={styles.configsTableColHeaderLabel}>
            ADC License
          </Typography>
        </Grid>

        <Grid item md={2} lg={2} xl={2}>
          <Typography variant="inherit" sx={styles.configsTableColHeaderLabel}>
            Mgd Scv Level *
          </Typography>
        </Grid>

        <AnalysisTitleGrid item md={3} lg={3} xl={2}>
          <Typography variant="inherit" sx={styles.configsTableColHeaderLabel}>
            Analysis
          </Typography>
        </AnalysisTitleGrid>
      </Grid>

      <Grid
        container
        alignItems="center"
        spacing={0}
        item
        sx={styles.tableWrapper}
      >
        {files.map((file) => (
          <Grid
            spacing={1}
            container
            item
            sx={{ ...styles.row, ...styles.tableRow }}
            md={12}
            xl={12}
            sm={12}
            key={file.id}
          >
            <Grid item md={1} sm={1} lg={1} xl={2} sx={styles.rowItem}>
              <StatusBanner>
                <StatusBannerText>{file.status}</StatusBannerText>
              </StatusBanner>
            </Grid>

            <Grid item sm={2} md={2} lg={2} xl={2} sx={styles.rowItem}>
              {file.isLoading && (
                <CircularProgressWrapper>
                  <CircularProgress color="secondary" size={20} />
                </CircularProgressWrapper>
              )}

              {!file.isLoading &&
                (file.bucketFileName ? (
                  <Link
                    sx={styles.linkRoot}
                    onClick={() => handleDownloadCDR(file)}
                    to="#download"
                    data-testid="bucket-file-link"
                  >
                    {file.instanceName}
                  </Link>
                ) : (
                  <Typography
                    variant="inherit"
                    sx={styles.configsTableColLabel}
                  >
                    {file.instanceName}
                  </Typography>
                ))}
            </Grid>

            <Grid item sm={2} md={2} lg={2} xl={2} sx={styles.rowItem}>
              <Typography variant="inherit" sx={styles.configsTableColLabel}>
                {file.adcFormFactor}
              </Typography>
            </Grid>

            <Grid item sm={2} md={2} lg={2} xl={2} sx={styles.rowItem}>
              <Typography variant="inherit" sx={styles.configsTableColLabel}>
                {file.adcLicense}
              </Typography>
            </Grid>

            <Grid item sm={2} md={2} lg={2} xl={2} sx={styles.rowItem}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <FormFieldSelect
                    size="fullWidth"
                    disabled={disabled}
                    field={{ ...configsTableScvLevelConfig }}
                    onChange={(e) => {
                      setFormDataChanged(true);
                      return onChange(e.target.value);
                    }}
                    value={value}
                    error={!value}
                    renderLabel={false}
                    testId={`formFieldSelect-${configsTableScvLevelConfig.key}`}
                  />
                )}
                key={`${configsTableScvLevelConfig.key}/${file.id}`}
                name={`${configsTableScvLevelConfig.key}/${file.id}`}
                defaultValue={file.level || ''}
              />
            </Grid>

            <AnalysisGrid item sm={3} md={3} lg={3} xl={2}>
              <Analysis
                instance={`${file.instanceName} Analysis`}
                analysisItems={file.analysis ? file.analysis.analysisItems : []}
              />
            </AnalysisGrid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default ConfigsTable;
