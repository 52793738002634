// @flow

import { Box } from '@mui/material';
import React from 'react';
import type { Node } from 'react';

const styles = {
  separator: {
    borderBottom: '1px solid #CCCCCC',
    width: '100%',
  },
};

function Separator(): Node {
  return <Box sx={styles.separator} />;
}

export default Separator;
