// @flow

import { styled, Button } from '@mui/material';
import React from 'react';
import type { Node } from 'react';
import { useNavigate } from 'react-router-dom';

const styles = {
  backButton: {
    margin: '18px 8px 0 0',
  },
  buttonsFooter: {
    display: 'flex',
    width: '100%',
  },
  actionButtons: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
};

type Props = {|
  rightButtons: any,
  leftButtons: any,
|};

const ButtonsFooter = styled('div')(() => styles.buttonsFooter);

const ActionButtons = styled('div')(() => styles.actionButtons);

function RequestDetailsActions(props: Props): Node {
  const { leftButtons, rightButtons } = props;
  const navigate = useNavigate();

  return (
    <ButtonsFooter>
      <ActionButtons>
        <Button
          sx={styles.backButton}
          variant="outlined"
          color="primary"
          onClick={() => navigate('/config-discovery-request')}
        >
          Back to list
        </Button>

        {leftButtons}
      </ActionButtons>

      {rightButtons && <div>{rightButtons}</div>}
    </ButtonsFooter>
  );
}

export default RequestDetailsActions;
