// @flow
/* istanbul ignore file */

import React from 'react';
import type { Node } from 'react';
// $FlowFixMe
import { Navigate, Routes, Route } from 'react-router-dom';
import ConfigDiscoveryRequests from './configDiscoveryRequests';
import RequestDetails from './requestDetails/index';

function AppRoutes(): Node {
  return (
    <Routes>
      <Route
        path="/config-discovery-request"
        element={<ConfigDiscoveryRequests />}
      />

      <Route path="/" element={<Navigate to="/config-discovery-request" />} />

      <Route path="/request-details" element={<RequestDetails />} />

      <Route path="/request-details/:id" element={<RequestDetails />} />
    </Routes>
  );
}

export default AppRoutes;
