// @flow

// $FlowFixMe
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';
import apiMiddleware from './apiMiddleware';

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store: any = createStore(
  reducers,
  composeEnhancers(applyMiddleware(apiMiddleware)),
);
/* eslint-enable */

export default store;
