// @flow
import * as React from 'react';
import type { Node } from 'react';
// $FlowFixMe
import { IconButton, Snackbar, Alert } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { hideSnack } from 'store/actions/snack';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'reducers';

function Snack(): Node {
  const dispatch = useDispatch();
  const snack = useSelector((state: RootState) => state.snack);
  const { message, severity } = snack;

  const handleClose = (event: Event, reason: string) => {
    if (reason && reason === 'clickaway') {
      /* istanbul ignore next */
      return;
    }

    dispatch(hideSnack());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={message !== ''}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            data-testid="close-snack"
            onClick={(e) => handleClose(e, 'iconClick')}
          >
            <ClearIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Snack;
