// @flow

import { styled, Tab, Tabs } from '@mui/material';
import React from 'react';
import type { Node } from 'react';
import { cdrStatuses } from 'constants/globalConsts';
import { createFormState } from 'constants/requestDetailsConsts';

const styles = {
  header: {
    display: 'flex',
  },
  headerTitle: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 700,
    margin: '0 0 24px 0',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  headerSecondTitle: {
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '22px',
    color: '#CCCCCC',
    margin: '0 0 4px 16px',
  },
  headerTabs: {
    margin: '0 0 24px 0',
  },
  tab: {
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 400,
    '&:hover': {
      color: '#0069AA',
      opacity: 1,
    },
    '&:focus': {
      color: '#0069AA',
    },
  },
};

type Props = {
  handleTabChange: () => void,
  currentTab: number,
  requestStatus: string,
  formState: string,
  title: string,
};

const Header = styled('div')(() => styles.header);
const HeaderTabs = styled('div')(() => styles.headerTabs);
const HeaderTitle = styled('div')(() => styles.headerTitle);
const SecondTitle = styled('span')(() => styles.headerSecondTitle);

function RequestDetailsTitle({
  handleTabChange,
  currentTab,
  requestStatus,
  formState,
  title,
}: Props): Node {
  const getHeaderTitle = () => (
    <HeaderTitle>
      Discovery Request Details
      {currentTab === 1 && <SecondTitle>{title}</SecondTitle>}
    </HeaderTitle>
  );

  return (
    <Header>
      {getHeaderTitle()}

      <HeaderTabs>
        <Tabs
          textColor="secondary"
          indicatorColor="secondary"
          value={currentTab}
          onChange={handleTabChange}
        >
          <Tab sx={styles.tab} label="Customer details" />

          <Tab
            sx={styles.tab}
            label="Calculation"
            disabled={
              formState === createFormState ||
              requestStatus !== cdrStatuses.completed
            }
          />
        </Tabs>
      </HeaderTabs>
    </Header>
  );
}

export default RequestDetailsTitle;
