// @flow

/* istanbul ignore file */

// $FlowFixMe
import axios from 'axios';
import { showSnack } from 'store/actions/snack';
import { defaultErrorMessage, snackSeverityType } from 'constants/globalConsts';
// $FlowFixMe
import { omit } from 'lodash';
import axiosWrapper from './axiosWrapper';

const cancelRequestMessage = 'CancelError';
let currentRequest = null;

const apiMiddleware = (store: any) => (next: any) => (action: any) => {
  if (!action.meta || action.meta.type !== 'api') {
    return next(action);
  }
  // This is an api request

  // Find the request URL and compose request options from meta
  const { url } = action.meta;

  if (currentRequest && currentRequest[action.type]) {
    currentRequest[action.type].cancelToken.cancel(cancelRequestMessage);
  }

  currentRequest = {
    ...currentRequest,
    [action.type]: {
      action: action.type,
      cancelToken: axios.CancelToken.source(),
    },
  };

  const options = {
    ...action.meta,
    cancelToken: currentRequest[action.type].cancelToken.token,
  };

  if (action.meta && action.meta.onLoading) {
    store.dispatch(action.meta.onLoading(true));
  }

  // Make the request
  return (
    axiosWrapper(url, options)
      // convert the response to json
      .then((resp) => {
        if (resp.status !== 200 && resp.status !== 201) {
          throw new Error('API exception, please try again!');
        }
        return resp;
      })
      .then((json) => {
        if (typeof action.meta.onSuccess === 'function') {
          action.meta.onSuccess(json.data);
        }
        const newAction = { ...action, payload: json.data };
        delete newAction.meta;
        return store.dispatch(newAction);
      })
      .catch((err: Error) => {
        if (err && err.message && err.message === cancelRequestMessage) {
          return null;
        }

        // $FlowFixMe
        const { response } = err;
        const { data, status } = response;

        if (status === 400) {
          store.dispatch(
            showSnack({
              message:
                data && data.errors ? data.errors[0] : defaultErrorMessage,
              severity: snackSeverityType.error,
            }),
          );
        } else if (status === 500) {
          store.dispatch(
            showSnack({
              message:
                data && data.message ? data.message : defaultErrorMessage,
              severity: snackSeverityType.error,
            }),
          );
        }

        if (typeof action.meta.onError === 'function') {
          action.meta.onError(err);
        }

        return null;
      })
      .finally(() => {
        currentRequest = omit(currentRequest, [action.type]);
        if (action.meta && action.meta.onLoading) {
          store.dispatch(action.meta.onLoading(false));
        }
      })
  );
};

export default apiMiddleware;
