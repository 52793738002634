// @flow

import React from 'react';
import type { Node } from 'react';
import { styled, Fab, Typography } from '@mui/material';
import IconArrowBackIos from '@mui/icons-material/ArrowBackIos';
import IconArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  navigationLeftButton: {
    paddingLeft: '8px',
    boxShadow: 'none',
  },
  navigationRightButton: {
    paddingLeft: '4px',
    boxShadow: 'none',
  },
  pageCount: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 'auto 8px',
    width: 'initial',
  },
};

const MainContainer = styled('div')(() => styles.container);

type Props = {
  handleNext: ?Function,
  handlePrev: ?Function,
  pageCount: number,
};

function Pagination({ handleNext, handlePrev, pageCount }: Props): Node {
  return (
    <MainContainer data-testid="pagination">
      <Fab
        data-testid="prev-pagination-button"
        size="small"
        disabled={!handlePrev}
        onClick={handlePrev}
        sx={styles.navigationLeftButton}
      >
        <IconArrowBackIos />
      </Fab>

      <Typography sx={styles.pageCount}>{pageCount}</Typography>

      <Fab
        data-testid="next-pagination-button"
        size="small"
        disabled={!handleNext}
        onClick={handleNext}
        sx={styles.navigationRightButton}
      >
        <IconArrowForwardIos />
      </Fab>
    </MainContainer>
  );
}

export default Pagination;
