// @flow
/* istanbul ignore file */

import React from 'react';
// $FlowFixMe
import ReactDOM from 'react-dom/client';
// $FlowFixMe
import { Provider } from 'react-redux';
// $FlowFixMe
import { ThemeProvider } from '@mui/material/styles';
// $FlowFixMe
import CssBaseline from '@mui/material/CssBaseline';
// $FlowFixMe
import { BrowserRouter as Router } from 'react-router-dom';
import DialogComponent from 'components/DialogComponent/DialogComponent';
import Snack from 'components/Snack';
import RequireAuth from 'utils/RequireAuth';
import { Authenticator } from '@aws-amplify/ui-react';
import mainTheme from './mainTheme';
import store from './store';
import AppRoutes from './routes';

require('dotenv').config();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Authenticator.Provider>
    <Provider store={store}>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <DialogComponent />
        <RequireAuth>
          <Router>
            <AppRoutes />
            <Snack />
          </Router>
        </RequireAuth>
      </ThemeProvider>
    </Provider>
  </Authenticator.Provider>,
);
