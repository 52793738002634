// @flow
import { sendButtonValue } from '../constants/requestDetailsConsts';

const buildRequestInfoData = (data, saveType, userData) => {
  const { instructionsForCustomer } = data;
  let requestData = {
    instructionsForCustomer,
    send: saveType === sendButtonValue,
    contactInfo: {},
    partner: {},
  };

  if (userData) {
    const { username } = userData;

    requestData = {
      ...requestData,
      creator: userData,
      creatorUsername: username,
    };
  }

  Object.keys(data).forEach((key) => {
    const keyParts = key.split('-');

    if (keyParts.length === 2) {
      const dataParentKey = keyParts[0];
      const dataKey = keyParts[1];

      if (dataParentKey === 'contactInfo' || dataParentKey === 'partner') {
        requestData[dataParentKey] = {
          ...requestData[dataParentKey],
          [dataKey]: data[key],
        };
      }
    }
  });

  return requestData;
};

const buildRequestOptionsData = (data) => {
  let requestData = {
    levels: [],
  };

  Object.keys(data).forEach((key) => {
    const keyParts = key.split('-');

    if (keyParts.length > 1) {
      const dataParentKey = keyParts[0];
      const dataKey = keyParts[1];

      if (dataParentKey === 'options') {
        const dataIdSplit = key.split('/');
        if (dataIdSplit.length > 1) {
          requestData.levels.push({
            level: data[key],
            fileId: dataIdSplit[1],
          });
        } else {
          requestData = {
            ...requestData,
            [dataKey]: data[key],
          };
        }
      }
    }
  });

  return requestData;
};

const addRequestDataToConfig = (data, configFields) => {
  let result = configFields;

  if (data) {
    result = configFields.map((fieldsRow) =>
      fieldsRow.map((field) => {
        const keyParts = field.key.split('-');
        let mappedField = {};

        if (keyParts.length === 2) {
          mappedField = {
            ...field,
            value: data[keyParts[1]],
          };
        } else {
          mappedField = {
            ...field,
            value: data[keyParts[0]],
          };
        }

        return mappedField;
      }),
    );
  }

  return result;
};

const parseRequestData = (data, parentKey) =>
  Object.keys(data).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [`${parentKey}-${key}`]: data[key],
    }),
    {},
  );

const parseRequestCustomerDataToConfig = (data) => {
  const { instructionsForCustomer, partner, contactInfo } = data;

  return {
    ...parseRequestData(partner, 'partner'),
    ...parseRequestData(contactInfo, 'contactInfo'),
    instructionsForCustomer,
  };
};

const addRequestDataToObjectConfg = (data, configFields) =>
  Object.keys(configFields).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: { ...configFields[key], value: data[key] },
    }),
    {},
  );

export {
  buildRequestInfoData,
  buildRequestOptionsData,
  addRequestDataToConfig,
  parseRequestCustomerDataToConfig,
  addRequestDataToObjectConfg,
};
