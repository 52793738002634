// @flow

import { snackSeverityType } from 'constants/globalConsts';

export type StateType = {
  message: string,
  severity: 'error' | 'warning' | 'info' | 'success',
};

export type Payload = {
  message: string,
  severity: 'error' | 'warning' | 'info' | 'success',
};

export const DefaultSnack = {
  message: '',
  severity: snackSeverityType.info,
};

type ShowSnack = {
  type: '@app/snack-show',
  payload: Payload,
};

type HideSnack = {
  type: '@app/snack-hide',
  payload: Payload,
};

type Action = ShowSnack | HideSnack;

// eslint-disable-next-line default-param-last
export default (state: StateType = DefaultSnack, action: Action): StateType => {
  switch (action.type) {
    case '@app/snack-show':
      return action.payload;

    case '@app/snack-hide':
      return {
        ...state,
        message: '',
      };

    default:
      return state;
  }
};
