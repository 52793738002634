// @flow

import ReducerConfig from 'store/reducers/cdr';
import ReducerDialog from 'store/reducers/dialog';
import ReducerRequestDetails from 'store/reducers/requestDetails';
import ReducerSnack from 'store/reducers/snack';
// $FlowFixMe
import { combineReducers } from 'redux';
import type { StateType as StateConfig } from 'store/reducers/cdr';
import type { StateType as StateDialog } from 'store/reducers/dialog';
import type { StateType as StateDetails } from 'store/reducers/requestDetails';
import type { StateType as StateSnack } from 'store/reducers/snack';

export type RootState = {
  config: StateConfig,
  dialog: StateDialog,
  details: StateDetails,
  snack: StateSnack,
};

const reducers: any = combineReducers({
  config: ReducerConfig,
  dialog: ReducerDialog,
  details: ReducerRequestDetails,
  snack: ReducerSnack,
});

export default reducers;
