// @flow
/* istanbul ignore file */

import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import type { Node } from 'react';
// $FlowFixMe
import { useDispatch, useSelector } from 'react-redux';
import {
  getConfigList,
  removeConfigFromList,
  getUsers,
  setFilters,
  updateConfig,
} from 'store/actions/cdr';
// $FlowFixMe
import { useNavigate } from 'react-router-dom';
import Layout from 'components/Layout';
import {
  archiveCDR,
  setDialogClose,
  setDialogOpen,
  expiryConfig,
} from 'store/actions/dialog';
import Pagination from 'components/Pagination';
import {
  createConfigDiscoveryRequest,
  cdrAction,
} from 'store/actions/requestDetails';
import CDRTable from 'components/CDRTable';
import { showSnack } from 'store/actions/snack';
import {
  snackSeverityType,
  snackMessageCopiedLink,
  snackMessageEmailSent,
} from 'constants/globalConsts';
import type { RootState } from 'reducers';

function ConfigDiscoveryRequests(): Node {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filters } = useSelector((state: RootState) => state.config);
  const { archived, creator, status, label, prevItem, currentItem, lastItem } =
    useSelector((state: RootState) => state.config.filters);
  const { currentUser } = useSelector((state: RootState) => state.config);
  const isDetailsLoading = useSelector(
    (state: RootState) => state.details.isLoading,
  );
  const prevButtonDisabled = prevItem && prevItem.length > 0;

  useEffect(() => {
    dispatch(getUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (creator === null) {
        dispatch(setFilters({ creator: currentUser.username }));
      }
      if (currentItem.id === 'initial') {
        dispatch(getConfigList(filters));
      } else {
        dispatch(getConfigList(filters, currentItem));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archived, creator, status, label, currentUser]);

  const handleArchiveConfig = (id: string) => {
    const onSuccess = () => {
      dispatch(setDialogClose());
      dispatch(removeConfigFromList(id));
      dispatch(
        showSnack({
          message: 'You have successfully archived a Config Discovery Request',
          severity: snackSeverityType.success,
        }),
      );
    };
    dispatch(archiveCDR(id, onSuccess));
  };

  const openArchiveDialog = (configData: Object) => {
    const payload = {
      isOpen: true,
      title: 'Are you sure?',
      textBody: `Do you want to archive ${configData.contactInfo.firstName} request?`,
      callback: () => handleArchiveConfig(configData.id),
    };
    dispatch(setDialogOpen(payload));
  };

  const handleExpiryConfig = (configData: Object) => {
    const onSuccess = (data: Object) => {
      dispatch(setDialogClose());
      dispatch(updateConfig(data));
      dispatch(
        showSnack({
          message:
            'You have successfully set the expiry of a Config Discovery Request',
          severity: snackSeverityType.success,
        }),
      );
    };
    dispatch(expiryConfig(configData.id, onSuccess));
  };

  const openExpiryDialog = (configData: Object) => {
    const payload = {
      isOpen: true,
      title: 'Are you sure?',
      textBody: `Do you want to extend the expiry time of a ${configData.contactInfo.firstName}'s config request?`,
      callback: () => handleExpiryConfig(configData),
    };
    dispatch(setDialogOpen(payload));
  };

  const getNextRequestsList = () => {
    if (!prevItem) {
      dispatch(
        setFilters({
          prevItem: [
            {
              id: 'initial',
              // $FlowFixMe
              creatorUsername: creator,
            },
          ],
        }),
      );
      dispatch(getConfigList(filters, lastItem));
    } else {
      // $FlowFixMe
      dispatch(setFilters({ prevItem: [...prevItem, currentItem] }));
      dispatch(getConfigList(filters, lastItem));
    }
    dispatch(
      setFilters({
        // $FlowFixMe
        currentItem: { ...lastItem, pageCount: currentItem.pageCount + 1 },
      }),
    );
  };

  const getPrevRequestsList = () => {
    if (!prevItem) {
      return;
    }

    const actualListId = prevItem.slice(0, prevItem.length - 1);

    if (prevItem.length === 1) {
      dispatch(
        setFilters({
          currentItem: {
            id: 'initial',
            // $FlowFixMe
            creatorUsername: creator,
            pageCount: currentItem.pageCount - 1,
          },
        }),
      );
      dispatch(setFilters({ prevItem: null }));
      dispatch(getConfigList(filters));
    } else {
      dispatch(
        setFilters({
          currentItem: {
            ...prevItem[prevItem.length - 1],
            pageCount: currentItem.pageCount - 1,
          },
        }),
      );
      dispatch(getConfigList(filters, prevItem[prevItem.length - 1]));
      dispatch(setFilters({ prevItem: actualListId }));
    }
  };

  const duplicateCDR = (data: Object) => {
    if (isDetailsLoading) {
      return null;
    }

    const { instructionsForCustomer, contactInfo, partner } = data;
    const { username, attributes } = currentUser || {};
    // eslint-disable-next-line camelcase
    const { email, sub, family_name, name } = attributes || {};

    return dispatch(
      createConfigDiscoveryRequest(
        {
          creator: { username, name, family_name, sub, email },
          creatorUsername: username,
          instructionsForCustomer,
          contactInfo,
          partner,
          send: false,
        },
        (responseData) => {
          if (responseData) {
            dispatch(
              showSnack({
                message:
                  'You have successfully duplicated a Config Discovery Request',
                severity: snackSeverityType.success,
              }),
            );

            navigate(`/request-details/${responseData.id}`);
          }
        },
      ),
    );
  };

  const openRequestDetailsPage = (requestId: string) =>
    navigate(`/request-details/${requestId}`);

  const handleCreate = () => navigate('/request-details');

  const handleSendEmail = (id: string) =>
    dispatch(
      cdrAction(id, 'send', (responseData) => {
        if (responseData) {
          dispatch(
            showSnack({
              message: snackMessageEmailSent,
              severity: snackSeverityType.success,
            }),
          );
        }
      }),
    );

  const handleGetLink = (id: string) => {
    const getLink = new Promise((resolve) => {
      dispatch(
        cdrAction(id, 'getLink', (responseData) => {
          if (responseData) {
            resolve(responseData.link);
          }
        }),
      );
    });

    // $FlowFixMe
    if (typeof ClipboardItem !== 'undefined' && navigator.clipboard.write) {
      const text = new ClipboardItem({
        // $FlowFixMe
        'text/plain': getLink.then(
          (link) => new Blob([link], { type: 'text/plain' }),
        ),
      });
      navigator.clipboard.write([text]);
    } else {
      // $FlowFixMe
      getLink.then((link) => navigator.clipboard.writeText(link));
    }
    dispatch(
      showSnack({
        message: snackMessageCopiedLink,
        severity: snackSeverityType.success,
      }),
    );
  };

  return (
    <Layout>
      <CDRTable
        openArchiveDialog={openArchiveDialog}
        openExpiryDialog={openExpiryDialog}
        openRequestDetailsPage={openRequestDetailsPage}
        duplicateCDR={duplicateCDR}
        handleSendEmail={handleSendEmail}
        handleGetLink={handleGetLink}
        archived={archived}
      />

      {(lastItem || prevItem) && (
        <Pagination
          handleNext={lastItem ? getNextRequestsList : null}
          handlePrev={prevButtonDisabled ? getPrevRequestsList : null}
          pageCount={currentItem.pageCount}
        />
      )}

      <Button variant="contained" color="primary" onClick={handleCreate}>
        Create new
      </Button>
    </Layout>
  );
}

export default ConfigDiscoveryRequests;
