// @flow

import React from 'react';
import type { Node } from 'react';
import { styled, Button, InputAdornment, Box } from '@mui/material';
import IconSearch from '@mui/icons-material/Search';
// $FlowFixMe
import { useDispatch, useSelector } from 'react-redux';
import { resetPagination, setFilters } from 'store/actions/cdr';
import { statuses } from 'store/reducers/cdr';
import FormFieldSelect from 'components/FormFieldSelect';
import type { RootState } from 'reducers';
import FormFieldText from '../FormFieldText';

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiFormControl-root': {
      border: 'none',
    },
  },
  switcherWrapper: {
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    background: '#FFFFFF',
    width: 189,
    height: 48,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectWrapper: {
    width: 140,
    borderRadius: '5px',
  },
  toggleButton: {
    fontSize: 11,
    lineHeight: '14.4px',
    fontWeight: 400,
    textAlign: 'center',
    width: 80,
    height: 32,
    boxShadow: 'none',
    textTransform: 'none',
    backgroundColor: 'none',
    color: '#CCCCCC',
    borderRadius: '5px',
    border: 'none',
    '&&.MuiButton-root.Mui-disabled': {
      borderRadius: '2px',
      background: '#F0F0F0',
      color: '#000000',
    },
  },
  searchIcon: {
    color: '#CCCCCC',
    margin: '0 15px 0 0',
  },
  textFieldStatus: {
    borderRadius: '5px',
  },
  textFieldInput: {
    width: '240px',
    borderRadius: '5px',
    borderColor: '#CCCCCC',
    lineHeight: '24px',
  },
};

const Container = styled('div')(() => styles.container);

const SwitcherContainer = styled('div')(({ theme }) => ({
  ...styles.switcherWrapper,
  padding: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const SelectContainer = styled('div')(({ theme }) => ({
  ...styles.selectWrapper,
  marginRight: theme.spacing(1),
}));

function Filters(): Node {
  const dispatch = useDispatch();
  const { archived, creator, status, label } = useSelector(
    /* istanbul ignore next */
    (state: RootState) => state.config.filters,
  );
  const { isUsersLoading, usersData } = useSelector(
    /* istanbul ignore next */
    (state: RootState) => state.config.allUsers,
  );

  const users = usersData?.map((el) => ({
    label: `${el.name} ${el.family_name}`,
    value: el.username,
  }));

  const handleCreactorClick = (value: string) => {
    dispatch(resetPagination());
    dispatch(setFilters({ creator: value === 'all' ? '' : value }));
  };

  const handleStatusChange = (value: string) =>
    dispatch(setFilters({ status: value === 'all' ? null : value }));

  return (
    <Container>
      <SwitcherContainer>
        <Button
          onClick={() => {
            dispatch(resetPagination());
            dispatch(setFilters({ archived: false }));
          }}
          disabled={!archived}
          sx={styles.toggleButton}
          data-testid="ongoing-button"
        >
          Ongoing
        </Button>

        <Button
          onClick={() => {
            dispatch(resetPagination());
            dispatch(setFilters({ archived: true }));
          }}
          disabled={archived}
          sx={styles.toggleButton}
          data-testid="archived-button"
        >
          Archived
        </Button>
      </SwitcherContainer>

      <SelectContainer sx={{ borderRadius: '5px' }}>
        <FormFieldSelect
          loading={isUsersLoading}
          field={{
            options: [{ label: 'All', value: 'all' }, ...(users || [])],
            placeholder: 'Creator',
            InputLabelProps: { shrink: true, focused: false },
            InputProps: { disableUnderline: true },
          }}
          onChange={(e) => handleCreactorClick(e.target.value)}
          value={creator || ''}
          renderLabel={false}
          testId="filters-select-creator"
          borderColor="grey"
          size="fullWidth"
        />
      </SelectContainer>

      <SelectContainer>
        <FormFieldSelect
          field={{
            options: statuses?.map((el) => ({
              label: el,
              value: el,
            })),
            placeholder: 'Status',
            InputLabelProps: { shrink: true, focused: false },
            InputProps: { disableUnderline: true },
          }}
          sx={{
            textFieldInput: styles.textFieldStatus,
          }}
          onChange={(e) => {
            dispatch(resetPagination());
            handleStatusChange(e.target.value);
          }}
          value={status || ''}
          renderLabel={false}
          borderColor="grey"
          size="fullWidth"
          testId="filters-select-status"
        />
      </SelectContainer>

      <Box>
        <FormFieldText
          field={{
            placeholder: 'Label',
            InputLabelProps: { shrink: true, focused: false },
            InputProps: { disableUnderline: true },
            focused: true,
          }}
          styles={{
            textFieldInput: styles.textFieldInput,
          }}
          onChange={(e) => {
            dispatch(resetPagination());
            dispatch(setFilters({ label: e.target.value }));
          }}
          value={label || ''}
          endAdornment={
            <InputAdornment role="img" position="end" sx={styles.searchIcon}>
              <IconSearch />
            </InputAdornment>
          }
          data-testid="formFieldText-label-search"
        />
      </Box>
    </Container>
  );
}

export default Filters;
